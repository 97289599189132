import React from 'react';
import {
  Box,
  Typography,
  Container,
  useMediaQuery,
  Button,
  Stack,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ReviewCarousel from '../components/ReviewCarousel.jsx';
import ReactGA from "react-ga4";
import theme from '../theme.jsx';
import { useNavigate } from 'react-router';
import { ArrowForward } from '@mui/icons-material';

const CustomerReviews = ({ isCloud, isLoggedIn }) => {
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('md'));
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        bgcolor: "#1A1A1A",
        color: "white",
        py: {
          xs: 25,
          md: 20,
          lg: 30,
        },
        paddingBottom: {
          xs: 30,
          lg: 10,
        },
        overflow: 'hidden',
        maxHeight: "1250px",
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Background gradient image */}
     
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 2 }}>
        <img
          src="/images/logos/orange-pink.png"
          style={{
            position: "absolute",
            top: isMobile ? 200 : -150,
            right: isMobile ? -50 : -300,
            height: isMobile ? 700 : 850,
            width: isMobile ? 600 : 1350,
            filter: "blur(150px)",
            mixBlendMode: "normal",
            rotate: "180deg",
            zIndex: -1,
            opacity: 0.5,
          }}
        />

        <Box sx={{ 
          display: 'flex', 
          flexDirection: {
            xs: 'column',
            md: 'row'
          },
          alignItems: {
            xs: "center",
          },
          gap: {
            xs: 4,
            md: 6
          },
          px: {
            xs: 2,
            sm: 0,
            md: 5,
            lg: 0
          },
        }}>
          <Box sx={{ maxWidth: {
            xs: "100%",
            md: "650px",
          },}}>
            <Typography
              variant="subtitle1"
              sx={{
                color: '#EE57A2',
                mb: 2,
                fontSize: '18px',
                fontWeight: 500,
                textAlign: {
                  xs: "center",
                  md: "left",
                },
              }}
            >
              Customer Reviews
            </Typography>

            <Typography
              variant="h2"
              sx={{
                fontSize: {
                  xs: "30px",
                  md: "35px",
                  lg: "40px",
                },
                fontWeight: 'bold',
                mb: 3,
                lineHeight: 1.2,
                textAlign: {
                  xs: "center",
                  md: "left",
                },
              }}
            >
              Real Problems,
              <br />
              Real Customer Stories
            </Typography>

            <Typography
              variant="body1"
              sx={{
                color: '#D8D8D8',
                mb: 4,
                fontSize: {
                  xs: "16px",
                },
                maxWidth: {
                  xs: "100%",
                  md: "500px",
                },
                textAlign: {
                  xs: "center",
                  md: "left",
                },
                px: {
                  xs: "30px",
                  sm: "80px",
                  md: "0",
                },
              }}
            >
              Be it a universal SOAR Usecase or an annoyingly complex customer problem, we've got you covered.
            </Typography>

              <Box sx={{display : "flex", alignItems: "center", flexDirection: "column", justifyContent: {
                xs: "center",
                md: "flex-start",
              },
              }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, paddingLeft: {
              xs: "10px",
              sm: "0",
            },
            }}>
              <Typography
                variant="body1"
                sx={{
                  color: '#D8D8D8',
                  fontSize: {
                    xs: "16px",
                    sm: "18px",
                  },
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <span style={{ color: '#EE57A2' }}>✓</span>
                Experience premium professional services.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: '#D8D8D8',
                  fontSize: {
                    xs: "16px",
                    sm: "18px",
                  },
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <span style={{ color: '#EE57A2' }}>✓</span>
                Get priority support when you choose our plan.
              </Typography>
               </Box>
               <Button
                variant="outlined"
                sx={{
                  color: "white",
                  borderColor: "rgba(255, 255, 255, 0.3)",
                  borderRadius: "8px",
                  padding: "12px 28px",
                  textTransform: "none",
                  alignSelf: {
                    xs: "center",
                    md: "flex-start",
                  },
                  marginTop: {
                    xs: "26px",
                    md: "24px",
                    lg: "28px",
                  },
                  fontSize: "16px",
                  "&:hover": {
                    borderColor: "white",
                    backgroundColor: "#494949",
                    border: "1px solid #494949",
                  },
                }}
                endIcon={<ArrowForward />}
                onClick={() => {
                  if (isCloud) {
                    ReactGA.event({
                      category: "new_landingpage",
                      action: "talk_to_sales_click",
                      label: "reviews_section_talk_to_sales_click",
                    })
                  }
                  navigate("/contact?category=talk_to_sales");
                }}
              >
                Talk to Sales
              </Button>
              </Box>
          </Box>

          <Box sx={{ 
            display: "flex",
            zIndex: 1,
            // flex: 1, 
            // position: 'relative',
            width: {
              xs: '100%',
              sm: '70%',
              md: '65%'
            },
            maxWidth: {
              xs: '100%',
              sm: '70%',
            },
            mx: {
              xs: 'auto',
            },
            mt: {
              xs: 2,
              sm: 4,
              md: 0
            },
          }}>
            <Box sx={{display: "flex", flexDirection: {
              xs: "column",
              lg: "row"
            }, 
            gap: {
              xs: "20px",
            }, 
            alignItems: {
              xs: "center",
              // sm: "flex-start",
            },
            }}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", gap: "10px", py: {
              xs: "30px",
              sm: "40px",
            }, px: {
              xs: "20px",
              sm: "25px",
            }, gap: "30px", borderRadius: "16px", backgroundColor: "rgba(255, 255, 255, 0.1)" }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", }}>
                <img src="/images/review.svg" alt="Customer Review" style={{ marginRight: "15px" }} />
                <Typography variant="body1" sx={{ 
                  color: '#F1F1F1', 
                  mb: 1, 
                  fontFamily: theme?.typography?.fontFamily, 
                  fontSize: {
                    xs: "16px",
                    sm: "18px",
                  },
                  "&::selection": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "#FB47A0",
                  },
                  "&::-moz-selection": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "#FB47A0",
                  },
                }}>
                “Shuffle's user-friendly automation workflows have significantly streamlined our security operations, allowing us to quickly detect and respond to threats.”
                </Typography>
              </div>
              <img src="/images/companies_logo/nio_white.svg" alt="NIO" style={{ marginBottom: "-10px", width: "80px"}}/>
            </Box>
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-start", gap: {
              xs: "20px",
            }}}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", py: {
              xs: "30px",
            }, px: {
              xs: "20px",
              sm: "25px",
            }, gap: "20px", backgroundColor: "rgba(255, 255, 255, 0.1)", borderRadius: "16px" }}>
                <Typography variant="body1" sx={{ 
                  color: '#F1F1F1', 
                  mb: 1, 
                  fontFamily: theme?.typography?.fontFamily, 
                  fontSize: {
                    xs: "16px",
                    sm: "18px",
                  },
                  "&::selection": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "#FB47A0",
                  },
                  "&::-moz-selection": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "#FB47A0",
                  },
                }}>
                “The sample workflows and seeing what others have done make learning the process so much easier.”</Typography>
                <Typography variant="body1" sx={{ color: '#F1F1F1', mb: 1, fontFamily: theme?.typography?.fontFamily, 
                "&::selection": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  color: "#FB47A0",
                },
                "&::-moz-selection": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  color: "#FB47A0",
                },
                fontSize: {
                  xs: "14px",
                  sm: "16px",
                } }}>
                – Shaunuk, Security Analyst
                </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", py: {
              xs: "30px",
            }, px: {
              xs: "20px",
              sm: "25px",
            }, gap: "20px", backgroundColor: "rgba(255, 255, 255, 0.1)", borderRadius: "16px" }}>
                <Typography variant="body1" sx={{ 
                  color: '#F1F1F1', 
                  mb: 1, 
                  fontFamily: theme?.typography?.fontFamily, 
                  fontSize: {
                    xs: "16px",
                    sm: "18px",
                  },
                  "&::selection": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "#FB47A0",
                  },
                  "&::-moz-selection": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "#FB47A0",
                  },
                }}>
                “Compared with other Soars it's way better to construct workflows and build custom integrations.”</Typography>
                <Typography variant="body1" sx={{ color: '#F1F1F1', mb: 1, fontFamily: theme?.typography?.fontFamily,
                "&::selection": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  color: "#FB47A0",
                },
                "&::-moz-selection": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  color: "#FB47A0",
                },
                fontSize: {
                  xs: "14px",
                  sm: "16px",
                } }}>
                – Grayson, Security Engineer
                </Typography>
            </Box>
            </Box>
            </Box>
            {/* <ReviewCarousel isMobile={isMobile} /> */}
          </Box>
        </Box>
      </Container>
      <Container 
        maxWidth="lg" 
        sx={{ 
          backgroundColor: "transparent",
          pb: 3,
          pt: {
            xs:4,
            md: 20,
          },
          mt: 8,
        }}
      >
        <Box
          sx={{
            backgroundColor: "#212121",
            zIndex: 3,
            borderRadius: "16px",
            // p: 8,
            py: 6,
            mx: {
              md: 5,
              lg: 0,
            },
            textAlign: "center",
            position: "relative",
            fontFamily: theme.typography.fontFamily,
            // marginLeft: "-60px",
            paddingTop: "50px",
            // width: "100%",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "#FF8544",
              fontSize: "16px",
              mb: 2,
              fontWeight: 500,
            }}
          >
            Free to Sign Up
          </Typography>

          <Typography
            variant="h2"
            sx={{
              color: "white",
              fontSize: {
                xs: "25px",
                sm: "35px",
                md: "40px",
              },
              fontWeight: 600,
              mb: 4,
              mt:{
                xs: 2,
                md: 0,
              },
              maxWidth: "900px",
              mx: "auto",
              px: {
                xs: 2,
                sm: 3,
                md: 0,
              },
            }}
          >
            Start using Shuffle now to automate with confidence.
          </Typography>

          <Stack
            direction={
              {
                xs: "column",
                sm: "row",
              }
            }
            spacing={2.5}
            justifyContent="center"
            sx={{ mt: 6, px: {
              xs: 3,
              md: 0,
            }}}
          >
            <Button
              variant="outlined"
              sx={{
                color: "white",
                borderColor: "rgba(255, 255, 255, 0.3)",
                borderRadius: "8px",
                padding: "12px 70px",
                textTransform: "none",
                fontSize: "16px",
                fontWeight: 600,
                "&:hover": {
                  borderColor: "white",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}
              onClick={() => {
                if (isCloud) {
                  ReactGA.event({
                    category: "new_landingpage",
                    action: "talk_to_sales_click",
                    label: "talk_to_sales_click",
                  })
                }
                navigate("/contact?category=talk_to_sales");
              }}
            >
              Talk to Sales
            </Button>
            <Button
              variant="contained"
              sx={{
                background: "linear-gradient(90deg, #FF8544 0%, #FB47A0 100%)",
                borderRadius: "8px",
                border: "none",
                padding: isLoggedIn ? "12px 70px" : "12px 60px",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "16px",
                position: "relative",
                isolation: "isolate",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  inset: 0,
                  background: "#FE804B",
                  borderRadius: "8px",
                  opacity: 0,
                  transition: "opacity 0.3s ease",
                  zIndex: -1,
                },
                "&:hover::before": {
                  opacity: 1,
                },
              }}
              onClick={() => {
                if (isCloud) {
                 if (isLoggedIn) {
                  ReactGA.event({
                    category: "new_landingpage",
                    action: "get_started_click",
                    label: "get_started_click",
                  })
                 } else {
                  ReactGA.event({
                    category: "new_landingpage",
                    action: "sign_up_for_free_click",
                    label: "sign_up_for_free_click",
                  })
                 }
                }
                navigate(`${isLoggedIn ? "/workflows" : "/register"}`);
              }}
            >
              {isLoggedIn ? "Get Started" : "Sign Up for Free"}
            </Button>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default CustomerReviews;
