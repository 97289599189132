import {
  Box,
  Button,
  Container,
  styled,
  Typography,
  TextField,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Autocomplete,
  Stack,
  CircularProgress,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import countries from "../components/Countries.jsx";
import ReactGA from "react-ga4";
import theme from "../theme.jsx";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const LogoTrack = styled("div")({
  display: "flex",
  // animation: "scroll 60s linear infinite",
  // "@keyframes scroll": {
  //   "0%": {
  //     transform: "translateX(0)",
  //   },
  //   "100%": {
  //     transform: "translateX(-50%)",
  //   },
  // },
});

const LogoSlide = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "120px",
  padding: "0 20px",
  marginLeft: "90px",
  flex: "0 0 auto",
  "& img": {
    height: "30px",
    maxHeight: "30px",
    width: "auto",
    objectFit: "contain",
    filter: "brightness(0) invert(1)",
    opacity: 0.9,
    transition: "opacity 0.1s ease",
    "&:hover": {
      opacity: 1,
    },
  },
  '& img[alt="HITACHI"], & img[alt="NEC"], & img[alt="NEOSOFT"], & img[alt="Axians"],  & img[alt="OKIOK"],  & img[alt="TANDEM"], & img[alt="SOPRA_STERIA"] , & img[alt="T_SYSTEMS"]':
    {
      height: "22px",
    },
  '& img[alt="PLAYPLUG"]': {
    height: "26px",
  },
  '& img[alt="HOSTINGER"]': {
    height: "34px",
  },
});

const benefitCards = [
  {
    icon: "/images/partner/for_company.svg",
    backgroundColor: "rgba(255, 133, 68, 0.2)",
    alt: "Features",
    title: "For your company – Better Pricing",
    description:
      "Pay less with straight discounts based on scale, customers, and more.",
  },
  {
    icon: "/images/partner/for_team.svg",
    backgroundColor: "rgba(251, 71, 160, 0.2)",
    alt: "Benefits",
    title: "For your team – Better Support",
    description:
      "On-call, critical issue alert email, and more on top of standard email support.",
  },
  {
    icon: "/images/partner/for_business.svg",
    backgroundColor: "rgba(43, 192, 126, 0.2)",
    alt: "Sell",
    title: "For your business – Better Growth",
    description:
      "A dedicated page, part of discovery search, and help to drive business growth.",
  },
  {
    icon: "/images/partner/much_more.svg",
    backgroundColor: "rgba(128, 107, 191, 0.2)",
    alt: "Products",
    title: "And much, much More.",
    description:
      "Additional resources, professional services package, partner training, and more.",
  },
];

const formStyles = {
  textField: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#212121",
      color: "white",
    },
    "& .MuiInputLabel-root": {
      color: "#D8D8D8",
    },
  },
  formSection: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    paddingBottom: "32px",
    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
    fontFamily: theme.typography.fontFamily,
  },
  flexRow: {
    display: "flex",
    gap: "24px",
    flexWrap: { xs: "wrap", sm: "nowrap" },
  },
  sectionTitle: {
    color: "#ffffff",
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: theme.typography.fontFamily,
  },
  submitButton: {
    mt: 2,
    py: 1.5,
    backgroundColor: "#FF8544",
    color: "black",
    borderRadius: "8px",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 600,
    width: "100%",
    mx: "auto",
    maxWidth: "320px",
    "&:hover": {
      background: "#FF955C",
    },
  },
};

const InputWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  width: "100%",
  fontFamily: theme.typography.fontFamily,
});

const Label = styled("div")({
  color: "#ffffff",
  fontSize: "16px",
  marginBottom: "3px",
  fontWeight: "500",
  fontFamily: theme.typography.fontFamily,
});

const StyledInput = styled("input")(({ error }) => ({
  padding: "16px",
  backgroundColor: "#212121",
  border: `1px solid ${error ? "#fd4c62" : "rgba(255, 255, 255, 0.1)"}`,
  borderRadius: "8px",
  color: "white",
  fontFamily: theme.typography.fontFamily,
  fontSize: "16px",
  "&::placeholder": {
    color: "#c5c5c5",
    fontWeight: "300",
  },
  "&:hover": {
    borderColor: error ? "#fd4c62" : "rgba(255, 255, 255, 0.3)",
  },
  "&:focus": {
    outline: "none",
    borderColor: error ? "#fd4c62" : "rgba(255, 255, 255, 0.3)",
  },
}));

const StyledTextArea = styled("textarea")(({ error }) => ({
  padding: "16px",
  backgroundColor: "#212121",
  fontFamily: theme.typography.fontFamily,
  border: `1px solid ${error ? "#fd4c62" : "rgba(255, 255, 255, 0.1)"}`,
  borderRadius: "8px",
  color: "white",
  fontSize: "16px",
  minHeight: "120px",
  resize: "vertical",
  fontFamily: "inherit",
  "&::placeholder": {
    color: "#808080",
  },
  "&:focus": {
    outline: "none",
    borderColor: error ? "#fd4c62" : "rgba(255, 255, 255, 0.3)",
  },
  "&:hover": {
    borderColor: error ? "#fd4c62" : "rgba(255, 255, 255, 0.3)",
  },
}));

const FormField = ({
  label,
  placeholder,
  type = "text",
  multiline = false,
  value,
  onChange,
  error,
  name,
}) => (
  <InputWrapper>
    <Label>{label}</Label>
    {multiline ? (
      <StyledTextArea
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        error={!!error}
      />
    ) : (
      <StyledInput
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        error={!!error}
      />
    )}
    {error && (
      <Typography
        sx={{
          color: "#fd4c62",
          fontSize: "12px",
        }}
      >
        {error}
      </Typography>
    )}
  </InputWrapper>
);

const fadeInAnimation = {
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
      transform: "translateY(30px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
};

const PartnerForm = ({ globalUrl, isCloud }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    companyName: "",
    country: null,
    interests: [],
    message: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [formStatus, setFormStatus] = useState({
    message: "",
    isSubmitting: false,
  });

  const interestOptions = [
    "I want to build integrations with Shuffle",
    "I want to use Shuffle as a part of my service offering",
    "I want to resell Shuffle",
    "Other (explain below)",
  ];

  const handleInputChange = (field) => (event) => {
    // Clear error for the changed field
    setFormErrors((prev) => ({
      ...prev,
      [field]: undefined
    }));

    // Update form data
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handleInterestChange = (option) => (event) => {
    setFormData((prev) => ({
      ...prev,
      interests: event.target.checked
        ? [...prev.interests, option]
        : prev.interests.filter((item) => item !== option),
    }));
  };

  const validateForm = () => {
    const errors = {};
    
    // Define validation rules matching backend
    const fieldValidations = [
      { value: formData.firstName, name: "firstName", label: "First name", minLen: 2, maxLen: 100 },
      { value: formData.lastName, name: "lastName", label: "Last name", minLen: 2, maxLen: 100 },
      { value: formData.companyName, name: "companyName", label: "Company name", minLen: 2, maxLen: 100 },
    ];

    // Validate all fields with length requirements
    fieldValidations.forEach(({ value, name, label, minLen, maxLen }) => {
      if (!value || value.length < minLen) {
        errors[name] = `${label} is too short (minimum ${minLen} characters)`;
      } else if (value.length > maxLen) {
        errors[name] = `${label} is too long (maximum ${maxLen} characters)`;
      }
    });

    // Email validation
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (formData.email.length > 254) {
      errors.email = "Email is too long (maximum 254 characters)";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = "Please fill a valid email";
    }

    // Country validation
    if (!formData.country) {
      errors.country = "Country is required";
    }

    // Interests validation
    if (!formData.interests || formData.interests.length < 1) {
      errors.interests = "Please select at least one interest";
    }

    // Message validation only if "Other" is selected
    const otherOptionSelected = formData.interests.includes("Other (explain below)");
    if (otherOptionSelected) {
      if (!formData.message || formData.message.length < 5) {
        errors.message = "Message is too short (minimum 5 characters)";
      } else if (formData.message.length > 1000) {
        errors.message = "Message is too long (maximum 1000 characters)";
      }
    }

    setFormErrors(errors);
    console.log(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (isCloud) => {
    if (!validateForm()) {
      toast.error(
        "Please fill all fields correctly"
      );
      return;
    }

    if(isCloud) {
      ReactGA.event({
        category: "NewPartnersPage",
        action: "Become a partner form submit",
        label: "Submit partner request",
      });
    }
    
    setFormStatus({ message: "", isSubmitting: true });

    try {
      const response = await fetch(`${globalUrl}/api/v1/contactpartner`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstname: formData.firstName,
          lastname: formData.lastName,
          email: formData.email,
          companyname: formData.companyName,
          dealcountry: formData.country?.label,
          category: "",
          message: formData.message,
          validate_email: formData.email,
          phone: "",
          dealtype: "Partnership",
          interests: formData.interests,
          title: formData.jobTitle,
        }),
      });

      const data = await response.json();

      if (data.success) {
        toast.success("Thanks for submitting!");
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          jobTitle: "",
          companyName: "",
          country: null,
          interests: [],
          message: "",
        });
      } else {
        throw new Error(data.reason || "Something went wrong");
      }
    } catch (error) {
      toast.error(
        error.message || "Please contact support@shuffler.io directly"
      );
      setFormStatus((prev) => ({
        ...prev,
        message: error.message || "Failed to submit form please contact support@shuffler.io directly",
      }));
    } finally {
      setFormStatus((prev) => ({ ...prev, isSubmitting: false }));
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        maxWidth: "700px",
        gap: "32px",
        paddingBottom: "60px",
        fontFamily: theme.typography.fontFamily,
      }}
    >
      {/* Personal Info Section */}
      <Box sx={formStyles.formSection}>
        <Box sx={formStyles.flexRow}>
          <FormField
            label="First Name"
            placeholder="First name"
            value={formData.firstName}
            onChange={handleInputChange("firstName")}
            error={formErrors.firstName}
          />
          <FormField
            label="Last Name"
            placeholder="Last name"
            value={formData.lastName}
            onChange={handleInputChange("lastName")}
            error={formErrors.lastName}
          />
        </Box>
        <Box sx={formStyles.flexRow}>
          <FormField
            label="Email Address"
            placeholder="example@email.com"
            value={formData.email}
            onChange={handleInputChange("email")}
            error={formErrors.email}
          />
          <FormField
            label="Job Title"
            placeholder="Enter your job title"
            value={formData.jobTitle}
            onChange={handleInputChange("jobTitle")}
            error={formErrors.jobTitle}
          />
        </Box>
        <Box sx={formStyles.flexRow}>
          <FormField
            label="Company Name"
            placeholder="Enter your company name"
            value={formData.companyName}
            onChange={handleInputChange("companyName")}
            error={formErrors.companyName}
          />
          <InputWrapper>
            <Label>Country</Label>
            <Autocomplete
              id="country-select"
              value={formData.country}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#212121",
                  color: "white",
                  borderRadius: "8px",
                  "& fieldset": {
                    border: `1px solid ${formErrors.country ? "#fd4c62" : "rgba(255, 255, 255, 0.1)"}`,
                    borderWidth: "1px",
                  },
                  "&:hover fieldset": {
                    borderColor: formErrors.country ? "#fd4c62" : "rgba(255, 255, 255, 0.3)",
                    borderWidth: "1px",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: formErrors.country ? "#fd4c62" : "rgba(255, 255, 255, 0.3)",
                    borderWidth: "1px",
                  },
                },
                "& .MuiAutocomplete-popupIndicator": {
                  color: "white",
                },
                "& .MuiAutocomplete-clearIndicator": {
                  color: "white",
                },
                "& .MuiAutocomplete-popper .MuiPaper-root": {
                  backgroundColor: "#212121",
                  color: "white",
                  borderRadius: "8px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                },
                "& .MuiAutocomplete-option": {
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  },
                  '&[aria-selected="true"]': {
                    backgroundColor: "rgba(255, 255, 255, 0.15)",
                  },
                },
              }}
              options={countries}
              autoHighlight
              getOptionLabel={(option) => option?.label || ""}
              onChange={(event, newValue) => {
                setFormErrors((prev) => ({
                  ...prev,
                  country: undefined
                }));
                setFormData((prev) => ({
                  ...prev,
                  country: newValue,
                }));
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.label} ({option.code}) +{option.phone}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select your country"
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "white",
                      height: "19px",
                      "&::placeholder": {
                        color: "#c5c5c5",
                        fontWeight: "300",
                        fontSize: "16px",
                        opacity: 1,
                      },
                    },
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                />
              )}
            />
              {formErrors.country && (
            <Typography
              sx={{
                color: "#fd4c62",
                fontSize: "12px",
              }}
            >
              {formErrors.country}
            </Typography>
          )}
          </InputWrapper>
        
        </Box>
      </Box>

      {/* Interest Section */}
      <Box sx={formStyles.formSection}>
        <Typography sx={formStyles.sectionTitle}>
          What are you interested in?
        </Typography>
        <FormGroup sx={{ marginTop: "-15px" }}>
          {interestOptions.map((option) => (
            <FormControlLabel
              key={option}
              control={
                <Checkbox
                  checked={formData.interests.includes(option)}
                  onChange={handleInterestChange(option)}
                  sx={{ color: "#c5c5c5", backgroundColor: "#212121" }}
                />
              }
              label={option}
              sx={{ color: "#ffffff" }}
            />
          ))}
        </FormGroup>
        {formErrors.interests && (
          <Typography
            sx={{
              color: "#ff4444",
              fontSize: "12px",
              mt: 1,
            }}
          >
            {formErrors.interests}
          </Typography>
        )}
      </Box>

      {/* Description Section */}
      <FormField
        label="Describe your partnership plan"
        placeholder="Anything we should know?"
        multiline
        value={formData.message}
        onChange={handleInputChange("message")}
        error={formErrors.message}
      />

      {/* Submit Button */}
      <Button
        variant="contained"
        sx={formStyles.submitButton}
        onClick={() => handleSubmit(isCloud)}
        disabled={formStatus.isSubmitting}
        startIcon={formStatus.isSubmitting ? <CircularProgress size={20} /> : null}
      >
        {formStatus.isSubmitting ? "Submitting..." : "Submit Partner Request"}
      </Button>

      {formStatus.message && (
        <Typography color="error" sx={{ textAlign: "center" }}>
          {formStatus.message}
        </Typography>
      )}
    </Box>
  );
};

const BenefitCard = ({ card, index }) => {
  const cardRef = React.useRef(null);
  const [isInView, setIsInView] = React.useState(false);
  const hasAnimated = React.useRef(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated.current) {
          setIsInView(true);
          hasAnimated.current = true;
          // Once the animation is triggered, we can disconnect the observer
          observer.disconnect();
        }
      },
      {
        threshold: 0.4 // Increased threshold for smoother trigger
      }
    );

    const currentRef = cardRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <Box
      ref={cardRef}
      sx={{
        flex: 1,
        zIndex: 1,
        backgroundColor: "#212121",
        borderRadius: "16px",
        padding: "32px",
        maxWidth: "480px",
        opacity: 0,
        animation: isInView ? "fadeIn 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards" : "none",
        animationDelay: `${index * 0.15}s`,
        ...fadeInAnimation,
      }}
    >
      <Box
        sx={{
          width: "44px",
          height: "44px",
          borderRadius: "50%",
          backgroundColor: card.backgroundColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1,
          mb: 4,
        }}
      >
        <img
          src={card.icon}
          alt={card.alt}
          style={{ width: "22px", height: "22px" }}
        />
      </Box>
      <Typography
        variant="h6"
        sx={{
          color: "#ffffff",
          fontSize: "20px",
          fontWeight: "600",
          mb: 1.5,
        }}
      >
        {card.title}
      </Typography>
      <Typography
        sx={{
          color: "#c5c5c5",
          fontSize: "16px",
          lineHeight: 1.5,
        }}
      >
        {card.description}
      </Typography>
    </Box>
  );
};

const Partners = ({ serverside, isLoggedIn, globalUrl }) => {
  const isCloud =
    serverside === true || typeof window === "undefined"
      ? true
      : window.location.host === "localhost:3002" ||
        window.location.host === "shuffler.io" ||
        window.location.host === "localhost:5002";

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // paddingTop: "100px", // Changing the padding top because hiding the logos and subtext for now : Uncomment this later
        paddingTop: "130px",
        overflowX: "hidden",
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#1A1A1A",
            gap: {
              xs: "90px",
              lg: "50px",
              xl: "90px",
            },
            width: "100%",
            position: "relative",

          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: -10,
              left: 170,
              height: "90%",
              width: "70%",
              rotate: "180deg",
              filter: "blur(120px)",
              zIndex: 0,
              opacity: 0.8,
            }}
          >
            <img
              src="/images/logos/orange-pink.png"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
              paddingTop: {
                xs: "100px",
                lg: "50px",
              },
              zIndex: 1,
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: {
                  xs: "36px",
                  sm: "50px",
                  xl: "64px",
                },
                marginTop: {
                  xl: "100px",
                },
                color: "#f1f1f1",
                textAlign: "center",
                fontWeight: "600",
                marginBottom: "16px",
                lineHeight: "1.3",
                letterSpacing: "-1px",
                fontFamily: theme.typography.fontFamily,
                px: {
                  xs: "10px",
                  md: "0",
                },
              }}
            >
              Building a Secure Future
              <br />
              with Our Partners
            </Typography>
            <Typography
              sx={{
                color: "#f1f1f1",
                fontSize: {
                  xs: "13px",
                  sm: "14px",
                  md: "16px",
                },
                textAlign: "center",
                maxWidth: "500px",
                marginBottom: "40px",
                marginTop: {
                  xs: "30px",
                  md: "20px",
                  lg: "10px",
                },
                lineHeight: "1.5",
                fontFamily: theme.typography.fontFamily,
              }}
            >
              Join our network of trusted partners to drive smarter, faster
              security automation. Together, we innovate to protect and
              strengthen digital ecosystems.
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
              }}
            >
              <Button
                sx={{
                  padding: {
                    xs: "12px 74px",
                    md: "12px 48px",
                  },
                  backgroundColor: "transparent",
                  border: "1px solid #f1f1f1",
                  borderRadius: "8px",
                  color: "#f1f1f1",
                  fontSize: {
                    xs: "12px",
                    md: "14px",
                    xl: "16px",
                  },
                  fontWeight: 600,
                  cursor: "pointer",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "white",
                  },
                }}
                onClick={() => {
                  const partnersSection = document.getElementById('become-partner');
                  if (partnersSection) {
                    partnersSection.scrollIntoView({ behavior: 'smooth' });
                  }
                  if(isCloud) {
                    ReactGA.event({
                      category: "NewPartnersPage",
                      action: "Hero section button click",
                      label: "Explore partners",
                    });
                  }
                }}
              >
                Explore partners
              </Button>
              <Button
                sx={{
                  background:
                    "linear-gradient(90deg, #FF8544 0%, #FB47A0 100%)",
                  borderRadius: "8px",
                  border: "none",
                  padding: {
                    xs: "12px 74px",
                    md: "12px 48px",
                  },
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: {
                    xs: "12px",
                    md: "14px",
                    xl: "16px",
                  },
                  position: "relative",
                  isolation: "isolate",
                  color: "white",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    inset: 0,
                    background: "#FE804B",
                    borderRadius: "8px",
                    opacity: 0,
                    transition: "opacity 0.3s ease",
                    zIndex: -1,
                  },
                  "&:hover::before": {
                    opacity: 1,
                  },
                }}
                onClick={() => {
                  const becomePartnerSection = document.getElementById('become-partner');
                  if (becomePartnerSection) {
                    becomePartnerSection.scrollIntoView({ behavior: 'smooth' });
                  }
                  if(isCloud) {
                    ReactGA.event({
                      category: "NewPartnersPage",
                      action: "Hero section button click",
                      label: "Become a partner",
                    });
                  }
                }}
              >
                Become a Partner
              </Button>
            </Box>
          </div>
          <p
            style={{
              color: "#D8D8D8",
              fontSize: "16px",
              zIndex: 1,
              paddingTop: "50px",
              textAlign: "center",
            }}
          >
            {/* Become a part of the future of security automation */}
          </p>
        </Box>
      </Container>

      {/* Hiding Partner logos for now */}
      <Box
        sx={{
          width: "100%",
          maxWidth: "1920px",
          overflow: "hidden",
          position: "relative",
          marginTop: "30px",
          zoom: {
            md: "0.8",
            xl: "1",
          },
          "&::before, &::after": {
            content: '""',
            position: "absolute",
            top: 0,
            height: "100%",
            width: "100px",
            zIndex: 2,
          },
          "&::before": {
            left: 0,
            background: "linear-gradient(to right, #1A1A1A, transparent)",
          },
          "&::after": {
            right: 0,
            background: "linear-gradient(to left, #1A1A1A, transparent)",
          },
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100px",
            alignItems: "center",
            backgroundColor: "transparent",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <LogoTrack>
            <LogoSlide>
              <img src="/images/companies_logo/axians.svg" alt="Axians" />
              <img src="/images/companies_logo/NEC.svg" alt="NEC" />
              <img src="/images/companies_logo/neosoft.svg" alt="NEOSOFT" />
              <img src="/images/companies_logo/okiok.svg" alt="OKIOK" />
              <img src="/images/companies_logo/nio.svg" alt="NIO" />
              <img src="/images/companies_logo/HITACHI.svg" alt="HITACHI" />
              <img
                src="/images/companies_logo/quinnipiac_uni.svg"
                alt="QUINNIPIAC_UNI"
              />
              <img src="/images/companies_logo/Hostinger.svg" alt="HOSTINGER" />
              <img src="/images/companies_logo/n4l.svg" alt="N4L" />
              <img src="/images/companies_logo/chk.svg" alt="CHK" />
              <img
                src="/images/companies_logo/sopra_steria.svg"
                alt="SOPRA_STERIA"
              />
              <img src="/images/companies_logo/T_systems.svg" alt="T_SYSTEMS" />
              <img src="/images/companies_logo/tandem.svg" alt="TANDEM" />
              <img src="/images/companies_logo/tarlogic.svg" alt="TARLOGIC" />
              <img src="/images/companies_logo/xtention.svg" alt="XTENTION" />
              <img src="/images/companies_logo/playplug.svg" alt="PLAYPLUG" />
            </LogoSlide>
            <LogoSlide>
              <img src="/images/companies_logo/axians.svg" alt="Axians" />
              <img src="/images/companies_logo/NEC.svg" alt="NEC" />
              <img src="/images/companies_logo/neosoft.svg" alt="NEOSOFT" />
              <img src="/images/companies_logo/okiok.svg" alt="OKIOK" />
              <img src="/images/companies_logo/nio.svg" alt="NIO" />
              <img src="/images/companies_logo/HITACHI.svg" alt="HITACHI" />
              <img
                src="/images/companies_logo/quinnipiac_uni.svg"
                alt="QUINNIPIAC_UNI"
              />
              <img src="/images/companies_logo/Hostinger.svg" alt="HOSTINGER" />
              <img src="/images/companies_logo/n4l.svg" alt="N4L" />
              <img src="/images/companies_logo/chk.svg" alt="CHK" />
              <img
                src="/images/companies_logo/sopra_steria.svg"
                alt="SOPRA_STERIA"
              />
              <img src="/images/companies_logo/T_systems.svg" alt="T_SYSTEMS" />
              <img src="/images/companies_logo/tandem.svg" alt="TANDEM" />
              <img src="/images/companies_logo/tarlogic.svg" alt="TARLOGIC" />
              <img src="/images/companies_logo/xtention.svg" alt="XTENTION" />
              <img src="/images/companies_logo/playplug.svg" alt="PLAYPLUG" />
            </LogoSlide>
          </LogoTrack>
        </Box> */}
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "140px",
          backgroundColor: "#1A1A1A",
          marginBottom: "50px",
          marginTop: "20px",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: {
              xs: "32px",
              md: "38px",
              lg: "40px",
            },
            color: "#f1f1f1",
            textAlign: "center",
            fontWeight: "bold",
            marginBottom: {
              xs: "84px",
              // md: "90px", // Uncomment this line when we've logos back 
              md: "130px",
            },
            px: {
              xs: "30px",
              md: "0",
            },
            lineHeight: {
              xs: "1.5",
              md: "0",
            },
            fontFamily: theme.typography.fontFamily,
          }}
        >
          Why join our Partner Program?
        </Typography>

        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              px: {
                xs: "10px",
                md: "0",
              },
            }}
          >
            {[0, 1].map((rowIndex) => (
              <Box
                key={rowIndex}
                sx={{
                  display: "flex",
                  gap: "16px",
                  width: "100%",
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {benefitCards
                  .slice(rowIndex * 2, (rowIndex + 1) * 2)
                  .map((card, index) => (
                    <BenefitCard 
                      key={index} 
                      card={card} 
                      index={index} 
                    />
                  ))}
              </Box>
            ))}
          </Box>
        </Container>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "160px",
          position: "relative",
          marginBottom: "100px",
          maxWidth: "1920px",
          fontFamily: theme.typography.fontFamily,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 70,
            height: "90%",
            width: "80%",
            rotate: "180deg",
            filter: "blur(90px)",
            zIndex: 0,
            opacity: 0.5,
          }}
        >
          <img
            src="/images/logos/orange-pink.png"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
        <Container maxWidth="lg" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", fontFamily: theme.typography.fontFamily }} >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "80px",
              px: {
                xs: "10px",
                md: "0",
              },
              gap: {
                xs: "20px",
                md: "0px",
              },
              zIndex: 1,
            }}
          >
            <Typography
              variant="h2"
              sx={{
                zIndex: 1,
                fontSize: {
                  xs: "28px",
                  md: "32px",
                  lg: "36px",
                },
                color: "#ffffff",
                fontWeight: "500",
                lineHeight: "1.3",
                maxWidth: "1200px",
                fontFamily: theme.typography.fontFamily,
                textAlign: "center",
              }}
            >
              At Shuffle, we lead the future in security automation.
            </Typography>
              <Typography
                sx={{
                  zIndex: 1,
                  fontSize: {
                    xs: "18px",
                    md: "22px",
                  },
                  color: "#ffffff",
                  fontWeight: "400",
                  lineHeight: "1.3",
                  fontFamily: theme.typography.fontFamily,
                  textAlign: "center",
                  marginTop: "15px",
                }}
              >
                Don't just take our word for it, hear what our partners have to say.
              </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "24px",
              width: "100%",
              marginBottom: "80px",
              minHeight: "300px",
              maxWidth: "900px",
              height: "100%",
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  minHeight: "170px",
                  gap: "10px",
                  py: {
                    xs: "30px",
                    sm: "40px",
                  },
                  px: {
                    xs: "20px",
                    sm: "25px",
                  },
                  gap: "30px",
                  borderRadius: "16px",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <img
                    src="/images/review.svg"
                    alt="Customer Review"
                    style={{ marginRight: "15px" }}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#F1F1F1",
                      mb: 1,
                      fontFamily: theme?.typography?.fontFamily,
                      fontSize: {
                        xs: "16px",
                        sm: "18px",
                      },
                      "&::selection": {
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        color: "#FB47A0",
                      },
                      "&::-moz-selection": {
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        color: "#FB47A0",
                      },
                    }}
                  >
                    “Shuffle's user-friendly automation workflows have
                    significantly streamlined our security operations, allowing
                    us to quickly detect and respond to threats.”
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                    marginBottom: "-10px", 
                  }}
                >
                  <img
                    src="/images/companies_logo/nio_white.svg"
                    alt="NIO"
                    style={{ width: "80px", color: "white",  }}
                  />
                  {/* <Button
                    disableRipple
                    component={Link}
                    to="/articles"
                    onClick={() => {
                      if (isCloud) {
                        ReactGA.event({
                          category: "NewPartnersPage",
                          action: "NIO article",
                          label: "Clicked Read more",
                        });
                      }
                    }}
                    endIcon={<span>→</span>}
                    sx={{
                      color: "#fb47a0",
                      textTransform: "none",
                      fontWeight: "600",
                      p: 0,
                      marginRight: "20px",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    Read more
                  </Button> */}
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  gap: "10px",
                  minHeight: "170px",
                  py: {
                    xs: "30px",
                    sm: "40px",
                  },
                  px: {
                    xs: "20px",
                    sm: "25px",
                  },
                  gap: "30px",
                  borderRadius: "16px",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <img
                    src="/partners/partnerReview.svg"
                    alt="Customer Review"
                    style={{ marginRight: "15px" }}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#F1F1F1",
                      mb: 1,
                      fontFamily: theme?.typography?.fontFamily,
                      fontSize: {
                        xs: "16px",
                        sm: "18px",
                      },
                      "&::selection": {
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        color: "#ff8544",
                      },
                      "&::-moz-selection": {
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        color: "#ff8544",
                      },
                    }}
                  >
                    “All our operations are functional and work as intended. Shuffle has significantly refined our automation processes.”
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                    marginBottom: "-10px", 
                    marginLeft: "3px",
                  }}
                >
                  <img
                    src="/images/companies_logo/safeEye.svg"
                    alt="SafeEye"
                    style={{ width: "120px", color: "white",  }}
                  />
                  {/* <Button
                    disableRipple
                    component={Link}
                    to="/articles"
                    onClick={() => {
                      if (isCloud) {
                        ReactGA.event({
                          category: "NewPartnersPage",
                          action: "atBay article",
                          label: "Clicked Read more",
                        });
                      }
                    }}
                    endIcon={<span>→</span>}
                    sx={{
                      color: "#ff8544",
                      textTransform: "none",
                      p: 0,
                      marginRight: "20px",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    Read more
                  </Button> */}
                </div>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box
        id="become-partner"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#212121",
          zIndex: 1,
          paddingTop: "60px",
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "60px",
            }}
          >
            {/* Icon and Title */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <img
                src="/partners/partners.svg"
                alt="Partner Icon"
                style={{ width: "80px", height: "80px" }}
              />
              <Typography
                variant="h2"
                sx={{
                  fontSize: "40px",
                  color: "#ffffff",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Become a Partner
              </Typography>
            </Box>

            {/* Form Container */}
            <PartnerForm globalUrl={globalUrl} isCloud={isCloud} />
          </Box>
        </Container>
      </Box>

      <Container
        maxWidth="lg"
        sx={{
          backgroundColor: "transparent",
          pb: 3,
          pt: {
            xs: 4,
            md: 10,
          },
          mt: 8,
        }}
      >
        <Box
          sx={{
            backgroundColor: "#212121",
            zIndex: 3,
            borderRadius: "16px",
            // p: 8,
            py: 8,
            mx: {
              md: 5,
              lg: 0,
            },
            textAlign: "center",
            position: "relative",
            fontFamily: theme.typography.fontFamily,
            // marginLeft: "-60px",
            paddingTop: "50px",
            // width: "100%",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "#FF8544",
              fontSize: "16px",
              mb: 2,
              fontWeight: 500,
            }}
          >
            Free to Sign Up
          </Typography>

          <Typography
            variant="h2"
            sx={{
              color: "white",
              fontSize: {
                xs: "25px",
                sm: "35px",
                md: "36px",
                lg: "40px",
              },
              fontWeight: 600,
              mb: 4,
              mt: {
                xs: 2,
                md: 0,
              },
              maxWidth: "900px",
              mx: "auto",
              px: {
                xs: 2,
                sm: 3,
                md: 0,
              },
            }}
          >
            Start using Shuffle now to automate with confidence.
          </Typography>

          <Stack
            direction={{
              xs: "column",
              sm: "row",
            }}
            spacing={2.5}
            justifyContent="center"
            sx={{
              mt: 6,
              px: {
                xs: 3,
                md: 0,
              },
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: "white",
                borderColor: "rgba(255, 255, 255, 0.3)",
                borderRadius: "8px",
                padding: "12px 70px",
                textTransform: "none",
                fontSize: "16px",
                fontWeight: 600,
                "&:hover": {
                  borderColor: "white",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}
              onClick={() => {
                if (isCloud) {
                  ReactGA.event({
                    category: "NewPartnersPage",
                    action: "book_a_demo_click",
                    label: "book_a_demo_click",
                  });
                }
                navigate("/contact?category=book_a_demo");
              }}
            >
              Book a Demo
            </Button>
            <Button
              variant="contained"
              sx={{
                background: "linear-gradient(90deg, #FF8544 0%, #FB47A0 100%)",
                borderRadius: "8px",
                border: "none",
                padding: isLoggedIn ? "12px 70px" : "12px 60px",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "16px",
                position: "relative",
                isolation: "isolate",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  inset: 0,
                  background: "#FE804B",
                  borderRadius: "8px",
                  opacity: 0,
                  transition: "opacity 0.3s ease",
                  zIndex: -1,
                },
                "&:hover::before": {
                  opacity: 1,
                },
              }}
              onClick={() => {
                if (isCloud) {
                  if (isLoggedIn) {
                    ReactGA.event({
                      category: "NewPartnersPage",
                      action: "get_started_click",
                      label: "get_started_click",
                    });
                  } else {
                    ReactGA.event({
                      category: "NewPartnersPage",
                      action: "sign_up_for_free_click",
                      label: "sign_up_for_free_click",
                    });
                  }
                }
                navigate(`${isLoggedIn ? "/workflows" : "/register"}`);
              }}
            >
              {isLoggedIn ? "Get Started" : "Sign Up for Free"}
            </Button>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default Partners;
