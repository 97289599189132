import React, { useState, useEffect } from 'react';
import theme from './theme.jsx';
import { initialize, send } from './utils/analytics';

import { useCookies } from 'react-cookie';
import { CookiesProvider } from 'react-cookie';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { isMobile } from "react-device-detect";
import Drift from "react-driftjs";

import { 
	Route, 
	Routes, 
	useNavigate,
	Link,
} from "react-router-dom";

import GettingStarted from "./views/GettingStarted.jsx";

import Workflows from "./views/Workflows.jsx";
import Workflows2 from "./views/Workflows2.jsx";
import Apps from './views/Apps.jsx';
import Apps2 from './views/Apps2.jsx';

import AngularWorkflow from "./views/AngularWorkflow.jsx";
import AppCreator from './views/AppCreator.jsx';
import Admin from "./views/Admin.jsx";
import Docs from "./views/Docs.jsx";
import Articles from './views/Articles.jsx';
import FAQView from './views/FAQView.jsx';
import SettingsPage from "./views/SettingsPage.jsx";
import CreatorEdit from "./views/creatoredit.jsx";

import DashboardViews from "./views/DashboardViews.jsx";
import Usecases from "./views/Usecases.jsx";
import Usecases2 from "./views/Usecases2.jsx";
import Admin2 from "./views/Admin2.jsx"
import HealthPage from "./components/HealthPage.jsx"
import Trainingcourses from "./views/Trainingcourses.jsx";
import ProfessionalSupport from "./components/ProfessionalSupport.jsx"
import DetectionWrapper from "./views/DetectionWrapper.jsx";
import Detections from "./views/Detections.jsx";
//import Detection from "./views/Detection.jsx";

// Cloud specific / old
//import EditSchedule from "./views/EditSchedule";
//import Schedules from "./views/Schedules";
//import Oauth2 from './views/Oauth2.js';
//import HandlePayment from "./views/HandlePayment";
//import LandingPage from "./views/Landingpage.jsx";
import ChatBot from "./components/ChatBot.jsx";
import Welcome from "./views/Welcome.jsx";
import Template from "./views/Template.jsx";
import history from './components/history.jsx'
import ForgotPassword from "./views/ForgotPassword.jsx";
import ForgotPasswordLink from "./views/ForgotPasswordLink.jsx";
import Blog from "./views/Blog.jsx";
import AppExplorer from './views/AppExplorer.jsx';
import HandlePaymentNew from "./views/HandlePaymentNew.jsx";
import Services from "./views/Services.jsx";
import Contact from './views/Contact.jsx';
import Partner from './views/Partner.jsx';
import RegisterLink from "./views/RegisterLink.jsx";
import InviteLink from "./views/InviteLink.jsx";
import HomePage from "./views/HomePage.jsx";
import LoginPage from "./views/LoginPage.jsx";
import Search from "./views/Search.jsx";
import Marketplace from "./views/Marketplace.jsx";
import Creators from "./views/Creators.jsx";
import UpdateAuthentication from "./views/UpdateAuthentication.jsx";
import SetAuthentication from "./views/SetAuthentication.jsx";
import SetAuthenticationSSO from "./views/SetAuthenticationSSO.jsx";
//import Footer from "./components/NewFooter.jsx";
import Creator from "./views/creator.jsx";
import RunWorkflow from "./views/RunWorkflow.jsx";
import Demo from "./views/Demo.jsx"
import StatisticalData from "./components/StatisticalData.jsx"
import RuntimeDebugger from "./components/RuntimeDebugger.jsx"

import DiscordChat from "./components/DiscordChat.jsx"
import FrameworkWrapper from "./views/FrameworkWrapper.jsx";
import ScrollToTop from './components/ScrollToTop.jsx';
import AlertTemplate from "./components/AlertTemplate.jsx";
import MFASetUp from './components/MFASetUP.jsx';
import ApiExplorerWrapper from './views/ApiExplorerWrapper.jsx';
import CodeWorkflow from './views/CodeWorkflow.jsx';
import NotFound from './views/404.jsx';

import LeftSideBar from './components/LeftSideBar.jsx';

import {
	Divider,
	Typography,
	IconButton,
	Dialog,
	DialogTitle,
	DialogActions,
	Button
} from '@mui/material'

import { AppContext } from './context/ContextApi.jsx';

import {
	Close as CloseIcon,
} from "@mui/icons-material";

import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './components/Navbar.jsx';
import NewFooter from './views/NewFooter.jsx';
import NewHomePage from './views/NewHomePage.jsx';
import PricingPage from './views/PricingPage.jsx';
import Partners from './views/Partners.jsx';

var serverside = false
var globalUrl = ""

/*
 * DONT INJECT WINDOW OR DOCUMENT from SSR! Instead have if statements!
*/

if (typeof window === 'undefined' || typeof document === 'undefined') {
	serverside = true
	global.window = {};
	global.document = {};
	global.navigator = {
		userAgent: 'node.js',
	};

	function copyProps(src, target) {
		const props = Object.getOwnPropertyNames(src)
			.filter(prop => typeof target[prop] === 'undefined')
			.map(prop => Object.getOwnPropertyDescriptor(src, prop));
		Object.defineProperties(target, props);
	}

	copyProps(window, global);
} else {
	// Production - backend proxy forwarding in nginx
	// Also works for cloud
	// Can cloud = true or something if this is shuffler.io.
	// CORS used for testing purposes. Should only happen with specific port and http
	globalUrl = window.location.origin
	const foundurl = localStorage.getItem("globalUrl")
	if (foundurl !== undefined && foundurl !== null && foundurl.length > 0) {
		globalUrl = foundurl
	}

	if (window.location.protocol === "http:" && (window.location.port === "3000" || window.location.port === "3001" || window.location.port === "3002" || window.location.port === "5002")) {
		//globalUrl = "http://localhost:5001"
		globalUrl = "http://localhost:5002"
	}
}

//platformColor: "#1c1c1d",
//const mainColor = "#1F2023"
const App = (props) => {
	const { selectedApp, selectedDoc, serverMobile, data, secondaryApp, } = props
	// LOADS of workarounds for serverside rendering here
	const [userdata, setUserData] = useState({});
	const [notifications, setNotifications] = useState([])
	const [cookies, setCookie, removeCookie] = useCookies([]);
	const [billingInfo, setBillingInfo] = React.useState({});
	const [modalOpen, setModalOpen] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isLoaded, setIsLoaded] = useState(serverside === true);
	const [curpath, setCurPath] = useState(typeof window === 'undefined' || window.location === undefined ? "" : window.location.pathname)

	// Old keys from 'Shuffle Website'
	//const stripeKey = typeof window === 'undefined' || window.location === undefined ? "" : window.location.origin === "https://shuffler.io" ? "pk_live_51PXYYMEJjT17t98N20qEqItyt1fLQjrnn41lPeG2PjnSlZHTDNKHuisAbW00s4KAn86nGuqB9uSVU4ds8MutbnMU00DPXpZ8ZD" : "pk_test_51PXYYMEJjT17t98NbDkojZ3DRvsFUQBs35LGMx3i436BXwEBVFKB9nCvHt0Q3M4MG3dz4mHheuWvfoYvpaL3GmsG00k1Rb2ksO"
	const stripeKey = typeof window === 'undefined' || window.location === undefined ? "" : window.location.origin === "https://shuffler.io" ? 
		"pk_live_51PXYYMEJjT17t98N20qEqItyt1fLQjrnn41lPeG2PjnSlZHTDNKHuisAbW00s4KAn86nGuqB9uSVU4ds8MutbnMU00DPXpZ8ZD" // Shuffle LLC live public key
		: 
		"pk_test_51PXYYMEJjT17t98NbDkojZ3DRvsFUQBs35LGMx3i436BXwEBVFKB9nCvHt0Q3M4MG3dz4mHheuWvfoYvpaL3GmsG00k1Rb2ksO" // Shuffle LLC test public key (Product should be created in Shuffle LLC)

	const mobileCheck = serverMobile === true ? true : isMobile
	const cursearch = typeof window === 'undefined' || window.location === undefined ? "" : window.location.search

	const mainColor = theme.palette.backgroundColor
	let navigate = useNavigate();

	const getUserNotifications = () => {
		fetch(`${globalUrl}/api/v1/notifications`, {
			credentials: "include",
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(response => {
				setIsLoaded(true)

				return response.json()
			})
			.then(responseJson => {
				if (responseJson.success === true && responseJson.notifications !== null && responseJson.notifications !== undefined && responseJson.notifications.length > 0) {
					//console.log("RESP: ", responseJson)
					setNotifications(responseJson.notifications)
				}
			})
			.catch(error => {
				console.log("Failed getting notifications for user: ", error)
				setIsLoaded(true)
			})
	}

	const checkLogin = () => {
		var baseUserdata = {}
		try {
			const storagewf = localStorage.getItem("userinfo")
			baseUserdata = JSON.parse(storagewf)
			if (baseUserdata === undefined || baseUserdata === null || baseUserdata.id === undefined || baseUserdata.id === null) {
				baseUserdata = {}
			} else {
				setUserData(baseUserdata)
				setIsLoggedIn(true)
				setIsLoaded(true)
			}
		} catch (e) {
			console.log("Failed to get userdata from localstorage: ", e)
		}

		const failover = localStorage.getItem("failover-me")
		var usefailover = true 
		if (failover !== undefined && failover !== null && failover.length > 1) {
			usefailover = true
		}

		const newurl = usefailover ? `${globalUrl}/api/v1/getinfo` : `${globalUrl}/api/v1/me`
		fetch(newurl, {
			credentials: "include",
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(response => {

				if (response.status === 404) {
					localStorage.setItem("failover-me", "true")
				}

				if (response.status === 401) {
					// Check if "auth" query in url. This is how the auth override system works
					if (window.location.search.includes("auth")) {
						console.log("Includes auth - returning as it's using org auth!")
						return
					}

					localStorage.setItem("globalUrl", "")
					localStorage.removeItem("apps")
					localStorage.removeItem("workflows")
	    			localStorage.removeItem("userinfo")

					setUserData({})


					if (document !== undefined && document !== null && document.cookie !== undefined && document.cookie !== null && document.cookie.length > 0) {
						const foundCookies = document.cookie.split(";")
						const foundCookie = foundCookies.filter(cookie => cookie.includes("session"))
						if (foundCookie !== undefined && foundCookie !== null && foundCookie.length > 0) {
							// Remove the cookies
							foundCookie.forEach(cookie => {
								document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
							})
						}
					}

					// 1. Find the current location
					// 2. Add it to view
					if (window.location.pathname === "/admin" || window.location.pathname === "/admin2") {
						window.location = `/login?view=admin&message=You must log in first to see your organization details`
						//console.log("WINDOW: ", window.location)
					} else if (window.location.pathname === "/apps/new") {
						if (window.location.search.length > 0) {
							window.location = `/login${window.location.search}&message=You must log in first to add this app&view=/apps/new`
						} else {
							window.location = `/login?view=/apps/new&message=You must log in first`
						}
					} else if (window.location.pathname === "/welcome") {
						// http://localhost:3002/welcome?tab=3&action=asd&workflow_template=EDR+Ticket+Enrichment&source_app=Microsoft_365_Defender&dest_app=TheHive_5
						if (window.location.search.length > 0) {
							window.location = `/login${window.location.search}&message=You must log in first&view=welcome`
						} else {
							window.location = `/login?view=welcome&message=You must log in first`

						}
					} 
					// else if (window.location.pathname === "/apps") {
					// 	window.location = `/login?view=apps&message=You must log in first to see your activated apps`
					// }
					 else if (window.location.pathname === "/workflows") {
						window.location = `/login?view=workflows&message=You must log in first to see your workflows`
					}
				}

				return response.json()
			})
			.then(responseJson => {
				//console.log("COOKIES: ", cookies)

				if (responseJson.success === true) {
					setUserData(responseJson)
					setIsLoggedIn(true)
					setIsLoaded(true)

					if (responseJson.id !== baseUserdata.id || responseJson.active_org.id !== baseUserdata.active_org.id) {  
						try {
							localStorage.setItem("userinfo", JSON.stringify(responseJson))
						} catch (e) {
							console.log("Failed to set userinfo in localstorage: ", e)
						}
					}

					if (userdata?.org_status?.includes("integration_partner")) {
						if (userdata?.active_org?.branding?.enable_chat !== true) {

							// Find the drift chatbox and remove it
						}
					}

					// Updating cookie every request
					for (var key in responseJson["cookies"]) {
						setCookie(
							responseJson["cookies"][key].key,
							responseJson["cookies"][key].value, {
							path: "/",
							domain: ".shuffler.io",
						}
						)
					}

					// Backend will always server shuffler.io url
					// Make sure it doesn't happen in dev
					// Make sure it's actually set everywhere
					if (responseJson.region_url !== undefined && responseJson.region_url !== null && responseJson.region_url !== globalUrl) {
						if (window.location.protocol === "http:" && (window.location.port === "3000" || window.location.port === "3001" || window.location.port === "3002" || window.location.port === "5002")) {
							//globalUrl = responseJson.region_url
						} else {
							globalUrl = responseJson.region_url
						}
							
						localStorage.setItem("globalUrl", responseJson.region_url)
					}

					getUserNotifications(globalUrl)

				} else {
					setIsLoaded(true)
				}
			})
			.catch(error => {
				localStorage.setItem("failover-me", "true")
				console.log("Failed login check: ", error)
				setIsLoaded(true)

				if (error.message === "Failed to fetch") {
					toast("Failed to load your account. Please reload the window.")
					localStorage.setItem("globalUrl", "")
					localStorage.removeItem("apps")
					localStorage.removeItem("workflows")
					localStorage.removeItem("userinfo")

					setUserData({})
				}
			})
	}

	useEffect(() => {
		initialize();

		if (!serverside) {
			checkLogin()
		}

		send({
			hitType: "pageview",
			page: curpath + cursearch,
		});
	}, [])

	if (!serverside && window.location.hostname !== "localhost") {
		send({
			hitType: "pageview",
			page: window.location.pathname + window.location.search,
		});
	}

	// Dumb for content load (per now), but good for making the site not suddenly reload parts (ajax thingies)
	//const options = {
	//	timeout: 6000,
	//	position: positions.BOTTOM_LEFT
	//}

	const handleFirstInteraction = (event) => {
		console.log("First interaction: ", event)
	}

	const header =
		<div> 
			{serverside ? null :
				!isLoaded ? null :
					userdata?.chat_disabled === true ? null :
						((curpath.includes("/workflows") && (curpath.includes("/form") || curpath.includes("/run"))) || curpath.includes("/conversation")) ? null :
							<Drift
								id="drift-widget"
								appId="zfk9i7w3yizf"
								attributes={{
									name: userdata.username === undefined || userdata.username === null ? "Cloud user" : `Cloud ${userdata.username}`,
									email: userdata.username === undefined || userdata.username === null ? "" : `${userdata.username}`,
								}}
								eventHandlers={[
									{
										event: "conversation:firstInteraction",
										function: handleFirstInteraction
									},
								]}
							/>
			}

			{ window.location.pathname === "/" || 
			  window.location.pathname === "/training" || 
			  window.location.pathname === "/articles" || 
			  window.location.pathname.includes("/articles/") ||
			  window.location.pathname === "/pricing" ||
			  window.location.pathname === "/old-pricing" ||
			  window.location.pathname === "/partners" ||
			  window.location.pathname === "/partner" ||
			  window.location.pathname === "/old-partners" ||
			  window.location.pathname === "/professional-services" ||
			  window.location.pathname === "/contact" ||
			  window.location.pathname === "/creators" ||
			  window.location.pathname === "/faq" ||
			  window.location.pathname === "/home" ||
			  !(isLoggedIn && isLoaded) ? (
				<div style={{ minHeight: 68, maxHeight: 68 }}>
					{serverside === true ? null : 
						<Navbar
							billingInfo={billingInfo}
							notifications={notifications}
							setNotifications={setNotifications}
							userdata={userdata}
							isMobile={mobileCheck}
							cookies={cookies}
							removeCookie={removeCookie}
							isLoaded={isLoaded}
							globalUrl={globalUrl}
							setIsLoggedIn={setIsLoggedIn}
							isLoggedIn={isLoggedIn}
							color={mainColor}
							serverside={serverside}
							curpath={curpath}
							{...props}
						/>
					}
				</div>
			) : (
				<div style={{ position: 'fixed', top: 32, left: 10, zIndex: 100000 }}>
				  <LeftSideBar checkLogin={checkLogin} userdata={userdata} globalUrl={globalUrl} serverside={serverside} notifications={notifications} />
				</div>
			) }
		</div>

		const routes = 
			<Routes>
				<Route exact path="/" element={<NewHomePage serverside={serverside} mainColor={mainColor} userdata={userdata} stripeKey={stripeKey} globalUrl={globalUrl} inputColor={theme.palette.inputColor} isLoaded={isLoaded} isLoggedIn={isLoggedIn} {...props} />} />
				<Route exact path="/home" element={<HomePage serverside={serverside} mainColor={mainColor} userdata={userdata} stripeKey={stripeKey} globalUrl={globalUrl} inputColor={theme.palette.inputColor} isLoaded={isLoaded} {...props} />} />
				<Route exact path="/homepage" element={<NewHomePage serverside={serverside} mainColor={mainColor} userdata={userdata} stripeKey={stripeKey} globalUrl={globalUrl} inputColor={theme.palette.inputColor} isLoaded={isLoaded} isLoggedIn={isLoggedIn} {...props} />} />
				<Route exact path="/nav" element={<Navbar />} />
				<Route exact path="/contact" element={<Contact serverside={serverside} isLoaded={isLoaded} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor} {...props} />} />
				<Route exact path="/faq" element={<FAQView serverside={serverside} isLoaded={isLoaded} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor} {...props} />} />
				<Route exact path="/login" element={<LoginPage serverside={serverside} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} inregister={true} isLoaded={isLoaded} globalUrl={globalUrl} setCookie={setCookie} cookies={cookies} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor} {...props} />} />
				<Route exact path="/login/:key/mfa-setup" element={<MFASetUp setCookie={setCookie} serverside={serverside} mainColor={mainColor} userdata={userdata} stripeKey={stripeKey} globalUrl={globalUrl} inputColor={theme.palette.inputColor} isLoaded={isLoaded} {...props} />} />
				<Route exact path="/register" element={<LoginPage serverside={serverside} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} inregister={false} isLoaded={isLoaded} globalUrl={globalUrl} setCookie={setCookie} cookies={cookies} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor} {...props} />} />
				<Route exact path="/services" element={<Services serverside={serverside} theme={theme} isLoaded={isLoaded} isMobile={mobileCheck} isLoggedIn={isLoggedIn} globalUrl={globalUrl} cookies={cookies} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/register/:key" element={<RegisterLink serverside={serverside} isLoaded={isLoaded} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/invite" element={<InviteLink serverside={serverside} isLoaded={isLoaded} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/passwordreset" element={<ForgotPassword serverside={serverside} isLoaded={isLoaded} isLoggedIn={isLoggedIn} globalUrl={globalUrl} cookies={cookies} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/passwordreset/:key" element={<ForgotPasswordLink serverside={serverside} isLoaded={isLoaded} isLoggedIn={isLoggedIn} globalUrl={globalUrl} cookies={cookies} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/blog/:key" element={<Blog serverside={serverside} isLoaded={isLoaded} isMobile={mobileCheck} isLoggedIn={isLoggedIn} globalUrl={globalUrl} cookies={cookies} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/settings" element={<SettingsPage serverside={serverside} isLoaded={isLoaded} userdata={userdata} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor} {...props} />} />
				<Route exact path="/search" element={<Search serverside={serverside} isLoaded={isLoaded} userdata={userdata} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor} {...props} />} />
				<Route exact path="/apps2" element={<Apps serverside={serverside} userdata={userdata} isLoaded={isLoaded} isLoggedIn={isLoggedIn} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor} {...props} />} />
				<Route exact path="/apps" element={<Apps2 serverside={serverside} userdata={userdata} isLoaded={isLoaded} isLoggedIn={isLoggedIn} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor} {...props} />} />
				<Route exact path="/apps/new" element={<AppCreator serverside={serverside} isLoaded={isLoaded} isLoggedIn={isLoggedIn} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor} {...props} />} />
				<Route exact path="/apps/edit/:appid" element={<AppCreator serverside={serverside} isLoaded={isLoaded} isLoggedIn={isLoggedIn} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor} {...props} />} />

				<Route exact path="/apps/:appid/integrations/:integrationid" element={<AppExplorer selectedDoc={selectedDoc} serverside={serverside} userdata={userdata} isLoggedIn={isLoggedIn} isMobile={mobileCheck} serverside={serverside} selectedApp={selectedApp} isLoaded={isLoaded} isLoggedIn={isLoggedIn} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor} secondApp={secondaryApp} checkLogin={checkLogin} {...props} />} />

				<Route exact path="/apps/:appid/integrations" element={<AppExplorer serverside={serverside} setUserData={setUserData} userdata={userdata} isLoggedIn={isLoggedIn} isMobile={mobileCheck} serverside={serverside} selectedApp={selectedApp} isLoaded={isLoaded} isLoggedIn={isLoggedIn} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor} checkLogin={checkLogin} {...props} />} />
				<Route exact path="/apps/:appid" element={<AppExplorer serverside={serverside} userdata={userdata} isLoggedIn={isLoggedIn} isMobile={mobileCheck} serverside={serverside} selectedApp={selectedApp} isLoaded={isLoaded} isLoggedIn={isLoggedIn} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor} checkLogin={checkLogin} {...props} />} />
				<Route exact path="/apis/:appid" element={<ApiExplorerWrapper serverside={serverside} userdata={userdata} isLoaded={isLoaded} isLoggedIn={isLoggedIn} isMobile={mobileCheck} serverside={serverside} selectedApp={selectedApp} isLoaded={isLoaded} isLoggedIn={isLoggedIn} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor} checkLogin={checkLogin} {...props} />} />
				<Route exact path="/apis" element={<ApiExplorerWrapper serverside={serverside} userdata={userdata} isLoaded={isLoaded} isLoggedIn={isLoggedIn} isMobile={mobileCheck} serverside={serverside} selectedApp={selectedApp} isLoaded={isLoaded} isLoggedIn={isLoggedIn} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor} checkLogin={checkLogin} {...props} />} />
				<Route exact path="/workflows2" element={<Workflows checkLogin={checkLogin} serverside={serverside} userdata={userdata} isLoaded={isLoaded} isLoggedIn={isLoggedIn} globalUrl={globalUrl} cookies={cookies} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/workflows" element={<Workflows2 checkLogin={checkLogin} serverside={serverside} userdata={userdata} isLoaded={isLoaded} isLoggedIn={isLoggedIn} globalUrl={globalUrl} cookies={cookies} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/workflows/:key" element={<AngularWorkflow ReactGA={send} serverside={serverside} setCookie={setCookie} userdata={userdata} globalUrl={globalUrl} isLoaded={isLoaded} isLoggedIn={isLoggedIn} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/workflows/:key/code" element={<CodeWorkflow serverside={false} userdata={userdata} globalUrl={globalUrl} isLoaded={isLoaded} isLoggedIn={isLoggedIn} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/workflows/:key/run" element={<RunWorkflow serverside={serverside} userdata={userdata} globalUrl={globalUrl} isLoaded={isLoaded} isLoggedIn={isLoggedIn} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/workflows/:key/form" element={<RunWorkflow serverside={serverside} userdata={userdata} globalUrl={globalUrl} isLoaded={isLoaded} isLoggedIn={isLoggedIn} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/workflows/:key/execute" element={<RunWorkflow serverside={serverside} userdata={userdata} globalUrl={globalUrl} isLoaded={isLoaded} isLoggedIn={isLoggedIn} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />


				<Route exact path="/forms" element={<RunWorkflow serverside={serverside} userdata={userdata} globalUrl={globalUrl} isLoaded={isLoaded} isLoggedIn={isLoggedIn} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/forms/:key/run" element={<RunWorkflow serverside={serverside} userdata={userdata} globalUrl={globalUrl} isLoaded={isLoaded} isLoggedIn={isLoggedIn} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/forms/:key" element={<RunWorkflow serverside={serverside} userdata={userdata} globalUrl={globalUrl} isLoaded={isLoaded} isLoggedIn={isLoggedIn} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />

				<Route
					exact
					path="/detections"
					element={<Detections globalUrl={globalUrl} />}
				/>
				<Route
					exact
					path="/detections/:key"
					element={ <DetectionWrapper serverside={serverside} userdata={userdata} globalUrl={globalUrl} isLoaded={isLoaded} isLoggedIn={isLoggedIn} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} /> }
				/>

				<Route exact path="/creators" element={<Creators serverside={serverside} userdata={userdata} globalUrl={globalUrl} isLoaded={isLoaded} isLoggedIn={isLoggedIn} surfaceColor={theme.palette.surfaceColor} setUserData={setUserData} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/debug" element={<RuntimeDebugger userdata={userdata} globalUrl={globalUrl} />} />
				<Route exact path="/workflows/debug" element={<RuntimeDebugger userdata={userdata} globalUrl={globalUrl} />} />
				<Route exact path="/creators/:key" element={<Creator serverside={serverside} userdata={userdata} globalUrl={globalUrl} isLoaded={isLoaded} isLoggedIn={isLoggedIn} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/creators/:key/edit" element={<CreatorEdit serverside={serverside} isLoaded={isLoaded} userdata={userdata} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor} {...props} />} />
				<Route exact path="/partners/:key" element={<Creator serverside={serverside} userdata={userdata} globalUrl={globalUrl} isLoaded={isLoaded} isLoggedIn={isLoggedIn} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/partners/:key/edit" element={<CreatorEdit serverside={serverside} isLoaded={isLoaded} userdata={userdata} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor} {...props} />} />
				<Route exact path="/creator" element={<Creators serverside={serverside} userdata={userdata} globalUrl={globalUrl} isLoaded={isLoaded} isLoggedIn={isLoggedIn} surfaceColor={theme.palette.surfaceColor} setUserData={setUserData} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/creators/:key?tab=${views[newValue]}`+extraQ" element={<Creators serverside={serverside} userdata={userdata} globalUrl={globalUrl} isLoaded={isLoaded} surfaceColor={theme.palette.surfaceColor} setUserData={setUserData} inputColor={theme.palette.inputColor}{...props} />} />
				<Route
					exact
					path="/detectionframework"
					element={
						<FrameworkWrapper
							serverside={serverside}
							selectedOption={"Draw"}
							showOptions={false}

							isLoaded={isLoaded}
							isLoggedIn={isLoggedIn}
							globalUrl={globalUrl}
							{...props}
						/>
					}
				/>

				<Route exact path="/apps/authentication" element={<UpdateAuthentication serverside={serverside} userdata={userdata} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} register={true} isLoaded={isLoaded} globalUrl={globalUrl} setCookie={setCookie} cookies={cookies} {...props} />} />
				<Route exact path="/appauth" element={<UpdateAuthentication serverside={serverside} userdata={userdata} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} register={true} isLoaded={isLoaded} globalUrl={globalUrl} setCookie={setCookie} cookies={cookies} {...props} />} />
				<Route exact path="/set_authentication" element={<SetAuthentication serverside={serverside} userdata={userdata} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} register={true} isLoaded={isLoaded} globalUrl={globalUrl} setCookie={setCookie} cookies={cookies} {...props} />} />
				<Route exact path="/login_sso" element={<SetAuthenticationSSO serverside={serverside} userdata={userdata} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} register={true} isLoaded={isLoaded} globalUrl={globalUrl} setCookie={setCookie} cookies={cookies} {...props} />} />

				<Route exact path="/articles" element={<Articles isLoggedIn={isLoggedIn} isLoaded={isLoaded} userdata={userdata} serverside={serverside} serverMobile={mobileCheck} selectedDoc={selectedDoc} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />

				<Route exact path="/legal/:key" element={<Docs isLoggedIn={isLoggedIn} isLoaded={isLoaded} userdata={userdata} serverside={serverside} serverMobile={mobileCheck} serverside={serverside} selectedDoc={selectedDoc} isLoaded={isLoaded} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/legal" element={<Docs isLoggedIn={isLoggedIn} isLoaded={isLoaded} userdata={userdata} serverside={serverside} isMobile={mobileCheck} serverside={serverside} selectedDoc={selectedDoc} isLoaded={isLoaded} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/articles/:key" element={<Docs isLoggedIn={isLoggedIn} isLoaded={isLoaded} userdata={userdata} serverside={serverside} serverMobile={mobileCheck} serverside={serverside} selectedDoc={selectedDoc} isLoaded={isLoaded} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/articles/:key/" element={<Docs isLoggedIn={isLoggedIn} isLoaded={isLoaded} userdata={userdata} serverside={serverside} isMobile={mobileCheck} serverside={serverside} selectedDoc={selectedDoc} isLoaded={isLoaded} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/docs/:key" element={<Docs isLoggedIn={isLoggedIn} isLoaded={isLoaded} userdata={userdata} serverside={serverside} serverMobile={mobileCheck} serverside={serverside} selectedDoc={selectedDoc} isLoaded={isLoaded} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/docs/:key/" element={<Docs isLoggedIn={isLoggedIn} isLoaded={isLoaded} userdata={userdata} serverside={serverside} isMobile={mobileCheck} serverside={serverside} selectedDoc={selectedDoc} isLoaded={isLoaded} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/docs" element={<Docs isLoggedIn={isLoggedIn} isLoaded={isLoaded} userdata={userdata} serverside={serverside} isMobile={mobileCheck} serverside={serverside} selectedDoc={selectedDoc} isLoaded={isLoaded} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/docs/" element={<Docs isLoggedIn={isLoggedIn} isLoaded={isLoaded} userdata={userdata} serverside={serverside} isMobile={mobileCheck} serverside={serverside} selectedDoc={selectedDoc} isLoaded={isLoaded} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/documentation" element={<Docs isLoggedIn={isLoggedIn} isLoaded={isLoaded} userdata={userdata} serverside={serverside} isMobile={mobileCheck} serverside={serverside} selectedDoc={selectedDoc} isLoaded={isLoaded} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/support" element={<Docs isLoggedIn={isLoggedIn} isLoaded={isLoaded} serverside={serverside} isMobile={mobileCheck} serverside={serverside} selectedDoc={selectedDoc} isLoaded={isLoaded} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/partners" element={<Partners serverside={serverside} isMobile={mobileCheck} serverside={serverside} selectedDoc={selectedDoc} isLoaded={isLoaded} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/old-partners" element={<Partner serverside={serverside} isMobile={mobileCheck} serverside={serverside} selectedDoc={selectedDoc} isLoaded={isLoaded} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route exact path="/partner" element={<Partners serverside={serverside} isMobile={mobileCheck} serverside={serverside} selectedDoc={selectedDoc} isLoaded={isLoaded} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor}{...props} />} />
				<Route
					exact
					path="/usecases"
					element={
						<Usecases2
							userdata={userdata}
							serverside={serverside}
							isLoaded={isLoaded}
							isLoggedIn={isLoggedIn}
							globalUrl={globalUrl}
							{...props}
						/>
					}
				/>
				<Route
					exact
					path="/usecases2"
					element={
						<Usecases
							userdata={userdata}
							serverside={serverside}
							isLoaded={isLoaded}
							isLoggedIn={isLoggedIn}
							globalUrl={globalUrl}
							{...props}
						/>
					}
				/>
				<Route
					exact
					path="/dashboard"
					element={
						<DashboardViews
							serverside={serverside}
							isLoaded={isLoaded}
							isLoggedIn={isLoggedIn}
							globalUrl={globalUrl}
							wut={userdata}
						/>
					}
				/> 
				<Route
					exact
					path="/dashboards"
					element={
						<DashboardViews
							serverside={serverside}
							isLoaded={isLoaded}
							isLoggedIn={isLoggedIn}
							globalUrl={globalUrl}
							wut={userdata}
						/>
					}
				/>
				<Route
					exact
					path="/dashboard/:key"
					element={
						<DashboardViews
							serverside={serverside}
							isLoaded={isLoaded}
							isLoggedIn={isLoggedIn}
							globalUrl={globalUrl}
							wut={userdata}
						/>
					}
				/>
				<Route
					exact
					path="/dashboards/:key"
					element={
						<DashboardViews
							serverside={serverside}
							isLoaded={isLoaded}
							isLoggedIn={isLoggedIn}
							globalUrl={globalUrl}
							wut={userdata}
						/>
					}
				/> 

				<Route
					exact
					path="/getting-started"
					element={
						<GettingStarted
							serverside={serverside}
							cookies={cookies}
							removeCookie={removeCookie}
							isLoaded={isLoaded}
							isLoggedIn={isLoggedIn}
							globalUrl={globalUrl}
							cookies={cookies}
							userdata={userdata}
							{...props}
						/>
					}
				/>
				<Route
					exact
					path="/welcome"
					element={
						<Welcome
							serverside={serverside}
							cookies={cookies}
							removeCookie={removeCookie}
							isLoaded={isLoaded}
							isLoggedIn={isLoggedIn}
							globalUrl={globalUrl}
							cookies={cookies}
							userdata={userdata}
							checkLogin={checkLogin}
							{...props}
						/>
					}
				/>
				<Route
					exact
					path="/template"
					element={
						<Template
							serverside={serverside}
							cookies={cookies}
							removeCookie={removeCookie}
							isLoaded={isLoaded}
							isLoggedIn={isLoggedIn}
							globalUrl={globalUrl}
							cookies={cookies}
							userdata={userdata}
							checkLogin={checkLogin}
							{...props}
						/>
					}
				/>
				<Route
					exact
					path="/statisticaldata"
					element={
						<StatisticalData
							serverside={serverside}
							cookies={cookies}
							removeCookie={removeCookie}
							isLoaded={isLoaded}
							isLoggedIn={isLoggedIn}
							globalUrl={globalUrl}
							cookies={cookies}
							userdata={userdata}
							{...props}
						/>
					}
				/>
				<Route
					exact
					path="/chat"
					element={
						<ChatBot
							serverside={serverside}
							cookies={cookies}
							removeCookie={removeCookie}
							isLoaded={isLoaded}
							isLoggedIn={isLoggedIn}
							globalUrl={globalUrl}
							cookies={cookies}
							userdata={userdata}
							{...props}
						/>
					}
				/>
				<Route
					exact
					path="/conversation"
					element={
						<ChatBot
							serverside={serverside}
							cookies={cookies}
							removeCookie={removeCookie}
							isLoaded={isLoaded}
							isLoggedIn={isLoggedIn}
							globalUrl={globalUrl}
							cookies={cookies}
							userdata={userdata}
							{...props}
						/>
					}
				/>
				<Route
					exact
					path="/admin"
					element={
						<Admin2
							serverside={serverside}
							cookies={cookies}
							removeCookie={removeCookie}
							stripeKey={stripeKey}
							isLoaded={isLoaded}
							isLoggedIn={isLoggedIn}
							notifications={notifications}
							setNotifications={setNotifications}
							globalUrl={globalUrl}
							checkLogin={checkLogin}
							userdata={userdata}
							{...props}
						/>
					}
				/>

				<Route exact path="/admin2" element={<Admin serverside={serverside} theme={theme} userdata={userdata} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} register={true} isLoaded={isLoaded} globalUrl={globalUrl} setCookie={setCookie} cookies={cookies} stripeKey={stripeKey} checkLogin={checkLogin} notifications={notifications} setNotifications={setNotifications} {...props} />} />
				<Route exact path="/pricing" element={<PricingPage serverside={serverside} theme={theme} serverside={serverside} isLoggedIn={isLoggedIn} isLoaded={isLoaded} userdata={userdata} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor} stripeKey={stripeKey} {...props} />} />
				<Route exact path="/old-pricing" element={<HandlePaymentNew serverside={serverside} theme={theme} serverside={serverside} isLoggedIn={isLoggedIn} isLoaded={isLoaded} userdata={userdata} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor} stripeKey={stripeKey} {...props} />} />
				<Route exact path="/pricing2" element={<HandlePaymentNew serverside={serverside} theme={theme} serverside={serverside} isLoggedIn={isLoggedIn} isLoaded={isLoaded} userdata={userdata} globalUrl={globalUrl} surfaceColor={theme.palette.surfaceColor} inputColor={theme.palette.inputColor} stripeKey={stripeKey} {...props} />} />

				<Route
					exact
					path="/health"
					element={
						<HealthPage
							serverside={serverside}
							cookies={cookies}
							removeCookie={removeCookie}
							isLoaded={isLoaded}
							isLoggedIn={isLoggedIn}
							globalUrl={globalUrl}
							cookies={cookies}
							userdata={userdata}
							{...props}
						/>
					}
				/>
				<Route
					exact
					path="/status"
					element={
						<HealthPage
							serverside={serverside}
							cookies={cookies}
							removeCookie={removeCookie}
							isLoaded={isLoaded}
							isLoggedIn={isLoggedIn}
							globalUrl={globalUrl}
							cookies={cookies}
							userdata={userdata}
							{...props}
						/>
					}
				/>
				<Route
					exact
					path="/discordchat"
					element={
						<DiscordChat
							serverside={serverside}
							cookies={cookies}
							removeCookie={removeCookie}
							isLoaded={isLoaded}
							isLoggedIn={isLoggedIn}
							globalUrl={globalUrl}
							cookies={cookies}
							userdata={userdata}
							{...props}
						/>
					}
				/>
				<Route
					exact
					path="/training"
					element={
						<Trainingcourses
							serverside={serverside}
							cookies={cookies}
							removeCookie={removeCookie}
							isLoaded={isLoaded}
							isLoggedIn={isLoggedIn}
							globalUrl={globalUrl}
							userdata={userdata}
							{...props}
						/>
					}
				/>
				<Route
					exact
					path="/professional-services"
					element={
						<ProfessionalSupport
							serverside={serverside}
							cookies={cookies}
							removeCookie={removeCookie}
							isLoaded={isLoaded}
							isLoggedIn={isLoggedIn}
							globalUrl={globalUrl}
							userdata={userdata}
							{...props}
						/>
					}
				/>

				<Route
					exact
					path="/*"
					element={
						<NotFound />
					}
				/>

			</Routes>

		const footer =
			<div>
				{(curpath === "/training" || curpath === "/creators" || curpath === "/" || curpath.includes("/docs") || curpath.includes("/articles") || curpath === "/pricing" || curpath === "/contact" || curpath.includes("/partner") || curpath === "/training-courses" || curpath === "/professional-services" || curpath === "/home" || curpath === "/usecases" || curpath === "/register" || curpath === "/login" || curpath === "/new-pricing" || curpath === "/new-partners") ?

					<span style={{ zIndex: 1000, position: "relative", }}>
						<NewFooter globalUrl={globalUrl} serverside={serverside} />
					</span>
					:
					null
				}
			</div>

	if (serverside === true) {
		// FIXME: This somehow doesn't return exactly what it should for SSR.
		return (
			<AppContext serverside={serverside}>
				<div className='parent-component' style={{ position: "relative", backgroundColor: mainColor, color: "rgba(255, 255, 255, 0.65)", minHeight: "100vh",}}>
					{header}
					{routes}
					{footer}
				</div>
			</AppContext>
		)
	}

	return (
		<AppContext serverside={serverside}>
			<ThemeProvider theme={theme}>
			<CookiesProvider>
				<ScrollToTop
					isLoggedIn={isLoggedIn}
					setCurpath={setCurPath}
					curpath={curpath}
				/>

				<div className='parent-component' style={{ position: "relative", backgroundColor: mainColor, color: "rgba(255, 255, 255, 0.65)", minHeight: "100vh",}}>
					{header}
					{routes}
					{footer}
				</div>

				<ToastContainer
					position="bottom-center"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="dark"
				/>
			</CookiesProvider>
		</ThemeProvider>
	  </AppContext>
	);
};

export default App;
