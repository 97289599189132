import React, { useEffect } from "react";
import {
  Typography,
  Container,
  Grid,
  Box,
  Button,
  Paper,
  Stack,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import HeroSection from "../components/HeroSection.jsx";
import NetworkVisualization from "../components/NetworkVisualization.jsx";
import theme from "../theme.jsx";
import { ArrowForward } from "@mui/icons-material";
import Capabilities from "../components/Capabilities.jsx";
import CustomerReviews from "../components/CustomerReviews.jsx";
import ReactGA from "react-ga4";
import NewWorkflowTemplatePopup from "../components/NewWorkflowTemplatePopup.jsx";

const usecaseData = [
  {
    srcImg: "https://storage.googleapis.com/shuffle_public/app_images/Gmail_794e51c3c1a8b24b89ccc573a3defc47.png",
    dstImg: "/images/hive.svg",
    name: "Email Analysis",
    srcapp: "Gmail",
    dstapp: "TheHive",
  },
  {
    srcImg: "https://storage.googleapis.com/shuffle_public/app_images/Elasticsearch_971706758e274c2e4083f2621fb5a6f7.png",
    dstImg: "/images/jira.svg",
    name: "SIEM Alert Enrichment",
    srcapp: "Elasticsearch",
    dstapp: "Jira",
  },
  {
    srcImg: "https://storage.googleapis.com/shuffle_public/app_images/Wazuh_fb715a176a192687e95e9d162186c97f.png",
    dstImg: "/images/Iris.svg",
    name: "SIEM To Ticket",
    srcapp: "Wazuh",
    dstapp: "Iris",
  },
  {
    srcImg: "/images/new_shuffle_logo.svg",
    dstImg: "/images/slack.svg",
    name: "EDR Ticket Enrichment",
    srcapp: "Shuffle Tools",
    dstapp: "Slack", 
  },
  {
    srcImg: "https://storage.googleapis.com/shuffle_public/app_images/MISP_c69ea55c02913030b1cd546f86187878.png",
    dstImg: "/images/jira.svg",
    name: "Internal Enrichment",
    srcapp: "MISP",
    dstapp: "NA",
  },
  {
    srcImg: "https://storage.googleapis.com/shuffle_public/app_images/Wazuh_fb715a176a192687e95e9d162186c97f.png",
    dstImg: "/images/jira.svg",
    name: "Search SIEM",
    srcapp: "Wazuh",
    dstapp: "Jira",
  }
];

const animationStyles = {
  row: {
    opacity: 0,
    transform: 'translateX(-30%)',
    transition: 'all 1.2s ease-in-out',
  },
  rowMiddle: {
    opacity: 0,
    transform: 'translateX(30%)',
    transition: 'all 1.2s ease-in-out',
  },
  animated: {
    opacity: 1,
    transform: 'translateX(0)',
  }
};

const NewHomePage = (props) => {
  const {
    serverside,
    mainColor,
    userdata,
    stripeKey,
    globalUrl,
    inputColor,
    isLoaded,
    isLoggedIn,
  } = props;

  const isCloud =
  serverside === true || typeof window === "undefined"
  ? true
  : window.location.host === "localhost:3002" ||
  window.location.host === "shuffler.io" ||
  window.location.host === "localhost:5002";


  const navigate = useNavigate();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1;
            entry.target.style.transform = 'translateX(0)';
          }
        });
      },
      { threshold: 0.2 }
    );

    const rows = document.querySelectorAll('.usecase-row');
    rows.forEach((row) => observer.observe(row));

    return () => {
      rows.forEach((row) => observer.unobserve(row));
    };
  }, []);

  return (
    <div style={{ backgroundColor: "#1A1A1A", overflowX: "hidden" }}>
      <HeroSection
        serverside={serverside}
        mainColor={mainColor}
        userdata={userdata}
        stripeKey={stripeKey}
        globalUrl={globalUrl}
        inputColor={inputColor}
        isLoaded={isLoaded}
        isLoggedIn={isLoggedIn}
      />

      <Typography
        variant="body1"
        sx={{
          color: "#FFFFFF",
          fontSize: {
            xs: "40px",
            sm: "45px",
            lg: "50px",
            xl: "55px",
          },
          px: {
            xs: 2,
            sm: 3,
            md: 4,
            lg: 10,
          },
          fontFamily: theme.typography.fontFamily,
          position: "relative",
          // zIndex: 2010,
          textAlign: "center",
          maxWidth: "1200px",
          mx: "auto",
          mb: 14,
          marginTop: "40px",
          paddingTop: "100px",
          paddingBottom: "100px",
          lineHeight: 1.2,
          fontWeight: 500,
        }}
      >
        Join the future of workflow automation with real-world AI agents.
      </Typography>

      <Container maxWidth="lg" sx={{ pb: 8 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column-reverse",
              md: "row",
            },
            alignItems: {
              xs: "center",
              lg: "start",
            },
            justifyContent: "space-between",
            gap: 6,
            width: "100%",
          }}
        >
          {/* Left Text Section */}
          <Box
            sx={{
              width: {
                xs: "100%",
                md: "50%",
              },
              height: "100%",
              zIndex: 10,
              marginTop: {
                xs: "0px",
                md: "25px",
                lg: "30px",
              },
              display: "flex",
              flexDirection: "column",
              alignItems: {
                xs: "center",
                md: "start",
              },
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#FF8544",
                fontSize: "16px",
                mb: 2,
                fontWeight: 500,
                marginLeft: {
                  xs: "0px",
                  md: "2px",
                },
              }}
            >
              Usecases
            </Typography>

            <Typography
              variant="h2"
              sx={{
                color: "white",
                fontSize: {
                  xs: "30px",
                  md: "40px",
                },
                fontWeight: 600,
                mb: 4,
                maxWidth: "600px",
                textAlign: {
                  xs: "center",
                  md: "start",
                },
              }}
            >
              Jump into action with our pre-built Usecases.
            </Typography>

            <Stack spacing={2} sx={{ mb: 4, px: {
              xs: 2,
              md: 0,
            } }}>
              {[
                "Save time with our ready-made templates.",
                "Built by security professionals for to ensure secure processes.",
                "Choose form a variety of our extensive library of pre-built playbooks.",
              ].map((text, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "center", gap: 2 }}
                >
                  <Typography
                    sx={{
                      color: "#C5C5C5",
                      fontSize: "16px",
                    }}
                  >
                    <span style={{ color: '#FF8544', marginRight: "10px", fontSize: "20px" }}>✓</span>
                    {text}
                  </Typography>
                </Box>
              ))}
            </Stack>

            <Button
              variant="outlined"
              component={Link}
              to="/usecases"
              endIcon={<ArrowForward />}
              sx={{
                color: "white",
                borderColor: "rgba(255, 255, 255, 0.3)",
                borderRadius: "8px",
                padding: "12px 24px",
                textTransform: "none",
                fontSize: "16px",
                "&:hover": {
                  borderColor: "white",
                  backgroundColor: "#494949",
                  border: "1px solid #494949",
                },
              }}
              onClick={() => {
                if (isCloud) {
                  ReactGA.event({
                    category: "new_landingpage",
                    action: "usecases_section_click",
                    label: "explore_usecases_templates_click",
                  })
                }
              }}
            >
              Explore Usecase Templates
            </Button>
          </Box>

          {/* Right Grid Section */}
          <Box sx={{ width: {
            xs: "100%",
            md: "50%",
          }, height: "100%", paddingTop: {
            xs: "0px",
            md: "40px",
            lg: "0px",
          } }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "18px",
                width: "100%",
                height: "100%",
                paddingLeft: "30px",
                marginTop: {
                  xs: "0px",
                  md: "200px",
                }
              }}
            >
              {[0, 1, 2].map((rowIndex) => (
                <Box
                  key={rowIndex}
                  className="usecase-row"
                  sx={{
                    ...rowIndex === 1 ? animationStyles.rowMiddle : animationStyles.row,
                    transitionDelay: `${rowIndex * 0.1}s`,
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    marginLeft: rowIndex === 1 ? "-36px" : {
                      xs: "-20px",
                      sm: "0",
                    },
                    position: "relative",
                  }}
                >
                  {/* Dummy box for first and last row (left side) */}
                  {(rowIndex === 0 || rowIndex === 2) && (
                    <Box
                      sx={{
                        display: {
                          xs: 'none',
                          lg: 'flex'
                        },
                        minWidth: "200px",
                        visibility: "visible",
                        position: "absolute",
                        left: "-46%",
                        opacity: "0.6",
                        zIndex: "1",
                        top: "0",
                        bottom: "0",
                        backgroundColor: "#212121",
                        borderRadius: "0 16px 16px 0",
                        padding: "28px 20px",
                        paddingRight: "40px",
                        paddingLeft: "24px",
                        alignItems: "start",
                        flexDirection: "column",
                        gap: "20px",
                        maskImage: "linear-gradient(to left, #212121 55%, transparent)",
                        WebkitMaskImage: "linear-gradient(to left, #212121 55%, transparent)",
                      }}
                    />
                  )}

                  {[0, 1].map((colIndex) => {
                    const dataIndex = rowIndex * 2 + colIndex;
                    const usecase = usecaseData[dataIndex];
                    
                    return (
                      <NewWorkflowTemplatePopup
                        key={colIndex}
                        userdata={userdata}
                        globalUrl={globalUrl}
                        img1={usecase.srcImg}
                        srcapp={usecase.srcapp}
                        img2={usecase.dstImg}
                        dstapp={usecase.dstapp}
                        title={usecase.name}
                        description=""
                        visualOnly={false}
                        isLoggedIn={isLoggedIn}
                        isHomePage={true}
                      />
                    );
                  })}

                  {/* Dummy box for middle row (right side) */}
                  {rowIndex === 1 && (
                    <Box
                      sx={{
                        display: {
                          xs: 'none',
                          lg: 'flex'
                        },
                        minWidth: "200px",
                        visibility: "visible",
                        position: "absolute",
                        right: "-46%",
                        opacity: "0.4",
                        zIndex: "1",
                        top: "0",
                        bottom: "0",
                        backgroundColor: "#212121",
                        borderRadius: "16px 0 0 16px",
                        padding: "28px 20px",
                        paddingRight: "40px",
                        paddingLeft: "24px",
                        alignItems: "start",
                        flexDirection: "column",
                        gap: "20px",
                        maskImage: "linear-gradient(to right, #212121 55%, transparent)",
                        WebkitMaskImage: "linear-gradient(to right, #212121 55%, transparent)"
                      }}
                    />
                  )}
                </Box>
              ))}
            </div>
          </Box>
        </Box>
      </Container>

      {/* Apps Library Section */}
      <Container maxWidth="lg" sx={{ pb: 8, mt: 11 }}>
        <Typography
          variant="h6"
          sx={{
            color: "#FB47A0",
            fontSize: "16px",
            mb: 2,
            fontWeight: 500,
            textAlign: "center",
            fontFamily: theme.typography.fontFamily,
          }}
        >
          Apps Library
        </Typography>

        <Typography
          variant="h2"
          sx={{
            color: "white",
            fontSize: {
              xs: "30px",
              md: "40px",
            },
            fontWeight: 600,
            mb: 3,
            textAlign: "center",
            fontFamily: theme.typography.fontFamily,
          }}
        >
          Integrate and build anything with<br />2,000+ Apps
        </Typography>

        <Typography
          sx={{
            color: "#F1F1F1",
            fontSize: {
              xs: "14px",
              md: "16px",
            },
            textAlign: "center",
            maxWidth: "600px",
            mx: "auto",
            px: {
              xs: 2,
              sm: 8,
              md: 0,
            },
            mb: 4,
          }}
        >
          Find any app you need with from our library of pre-built apps for speed or use the
          <Box component="span" sx={{ color: "#F1F1F1", fontWeight: 700 }}> No-Code </Box>
          app creator for customization and flexibility.
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center", mb: 8, mt: 2, 
          display: {
          xs: "none",
          md: "block",
        },
        width: "100%",
        textAlign: "center",
        }}>
          <Button
            variant="outlined"
            component={Link}
            to="/apps"
            endIcon={<ArrowForward />}
            sx={{
              color: "white",
              borderColor: "rgba(255, 255, 255, 0.3)",
              borderRadius: "8px",
              padding: "12px 26px",
              textTransform: "none",
              fontSize: "16px",
              "&:hover": {
                borderColor: "white",
                backgroundColor: "#494949",
                border: "1px solid #494949",
              },
            }}
            onClick={() => {
              if (isCloud) {
                ReactGA.event({
                  category: "new_landingpage",
                  action: "apps_section_click",
                  label: "explore_apps_click",
                })
              }
            }}
          >
            Explore Apps
          </Button>
        </Box>

        {/* App connection visualization */}
        <Box 
          sx={{ 
            width: '100%', 
            display: {
              xs: "flex",
              sm: "none",
            }, 
            marginTop: {
              xs: "60px",
              md: "0px",
            },
            justifyContent: 'center',
            paddingBottom: "50px",
            '& img': {
              maxWidth: '100%',
              height: 'auto'
            }
          }}
        >
          <img 
            src="/images/appConnectionMobile.svg" 
            alt="App Connections Visualization" 
            loading="lazy"
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", mb: 8, mt: 2, 
          display: {
          xs: "block",
          md: "none",
        },
        textAlign: "center",
        width: "100%",
        }}>
          <Button
            variant="outlined"
            component={Link}
            to="/apps"
            endIcon={<ArrowForward />}
            sx={{
              width: {
                xs: "80%",
                sm: "30%",
              },
              color: "white",
              borderColor: "rgba(255, 255, 255, 0.3)",
              borderRadius: "8px",
              padding: "12px 26px",
              textTransform: "none",
              fontSize: "16px",
              "&:hover": {
                borderColor: "white",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              },
            }}
          >
            Explore Apps
          </Button>
        </Box>

        <Box 
          sx={{ 
            display: {
              xs: "none",
              sm: "flex",
            },
            width: '100%', 
            height: '100%',
            justifyContent: 'center',
            paddingBottom: "50px",
          }}
        >
          <NetworkVisualization/>
        </Box>
      </Container>

      {/* <Container maxWidth="xl" sx={{ pb: 8, mt: 11 }}> */}
        <Capabilities/>
      {/* </Container> */}

      <CustomerReviews isCloud={isCloud} isLoggedIn={isLoggedIn} />

      {/* CTA Container */}
    
    </div>
  );
};

export default NewHomePage;
