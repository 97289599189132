import ReactGA from 'react-ga4';

let initialized = false;

export const initialize = () => {
  const consent = localStorage.getItem('cookieConsent');
  if (consent === 'accepted' && !initialized) {
    ReactGA.initialize([
      {
        "trackingId": "G-ENDMKDPL5B"
      },
      {
        "trackingId": "UA-147420730-1"
      }
    ])
    initialized = true
  }
};

export const event = (...args) => {
  const consent = localStorage.getItem('cookieConsent')
  if (consent === 'accepted') {
    ReactGA.event(...args);
  }
}

export const send = (...args) => {
  const consent = localStorage.getItem('cookieConsent')
  if (consent === 'accepted') {
    ReactGA.send(...args)
  }
} 
