import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  Paper,
  Stack,
  Chip,
  styled,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import theme from "../theme.jsx";
import { usecaseTypes } from "../components/UsecaseSearch.jsx";
import Lottie from 'lottie-react';
import { useTheme } from '@mui/material/styles';
import { usecases } from "../components/DetectionFrameworkOld.jsx";
import ReactGA from "react-ga4";
// Styled components
const GradientButton = styled(Button)(({ theme }) => ({
  position: "relative",
  isolation: "isolate",
  background: "linear-gradient(90deg, #FF8544 0%, #FB47A0 100%)",
  borderRadius: "8px",
  padding: "16px 48px",
  color: "white",
  fontSize: "16px",
  fontWeight: 600,
  fontFamily: theme.typography.fontFamily,
  textTransform: "none",
  "&::before": {
    content: '""',
    position: "absolute",
    inset: 0,
    background: "#FE804B",
    borderRadius: "8px",
    opacity: 0,
    transition: "opacity 0.3s ease",
    zIndex: -1,
  },
  "&:hover::before": {
    opacity: 1,
  },
}));

const WorkflowCard = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  width: "100%",
  px: {
    xs: "200px",
    sm: "0px",
  },
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    cursor: 'pointer',
    inset: 0,
    borderRadius: '16px',
    padding: '1px', // Border width
    background: 'linear-gradient(90deg, transparent, transparent)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    transition: 'background 0.3s ease-in-out',
  },
  '&:hover::before': {
    background: '#FFFFFF',
  },
}));

const AnimatedBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '89px', // Match the line height
  display: 'flex',
  alignItems: 'center',
  width: '100%', // Add explicit width
  '@media (max-width: 900px)': {
    height: '66px', // Match mobile line height
    width: '100%', // Mobile width
  },
}));

const AnimatedText = styled(Box)(({ entering }) => {
	return ({
	  position: 'absolute',
	  display: 'flex',
	  // alignItems: 'flex-start',
	  justifyContent: 'flex-start',
	  left: 0,
	  width: '100%', // Add full width
	  animation: entering ? 
		'slideIn 0.5s ease-out forwards' : 
		'slideOut 0.5s ease-out forwards',
	  '@keyframes slideIn': {
		'0%': {
		  transform: 'translateY(20%)',
		  opacity: 0,
		},
		'100%': {
		  transform: 'translateY(0)',
		  opacity: 1,
		},
	  },
	  '@keyframes slideOut': {
		'0%': {
		  transform: 'translateY(0)',
		  opacity: 1,
		},
		'100%': {
		  transform: 'translateY(-70%)',
		  opacity: 0,
		},
	  },
	})
})

// Add this new styled component near the other styled components
const AnimatedWorkflowText = styled(Typography)(({ entering }) => {
	return ({
	  animation: entering ? 
		'workflowSlideIn 0.5s ease-out forwards' : 
		'workflowSlideOut 0.5s ease-out forwards',
	  '@keyframes workflowSlideIn': {
		'0%': {
		  transform: 'translateY(20%)',
		  opacity: 0,
		},
		'100%': {
		  transform: 'translateY(0)',
		  opacity: 1,
		},
	  },
	  '@keyframes workflowSlideOut': {
		'0%': {
		  transform: 'translateY(0)',
		  opacity: 1,
		},
		'100%': {
		  transform: 'translateY(-20%)',
		  opacity: 0,
		},
	  },
	})
})


// Add this constant for images mapping
const images = {
  email: [
    "https://storage.googleapis.com/shuffle_public/app_images/Gmail_794e51c3c1a8b24b89ccc573a3defc47.png",
    "https://storage.googleapis.com/shuffle_public/app_images/Outlook_Office365_accdaaf2eeba6a6ed43b2efc0112032d.png",
  ],
  intel: [
    "https://storage.googleapis.com/shuffle_public/app_images/Yara_2b1264f213f3636682cc51d49ee470be.png",
    "https://storage.googleapis.com/shuffle_public/app_images/MISP_c69ea55c02913030b1cd546f86187878.png",
  ],
  communication: [
    "https://storage.googleapis.com/shuffle_public/app_images/Slack_d641997f3f1657ec4b0c91cba020838b.png",
    "/images/teams.png",
  ],
  edr: [
    "https://storage.googleapis.com/shuffle_public/app_images/Carbon_Black_Response_e9fa2602ea6baafffa4b5eec722095d3.png",
    "https://storage.googleapis.com/shuffle_public/app_images/Wazuh_fb715a176a192687e95e9d162186c97f.png",
  ],
  cases: [
    "/images/hive.svg",
    "https://storage.googleapis.com/shuffle_public/app_images/Zoho_Desk_14b085b64bf74d7ace3afb3d3406a21c.png",
    "https://storage.googleapis.com/shuffle_public/app_images/Servicenow_API_b9c2feaf99b6309dabaeaa8518c61d3d.png",
  ],
  siem: [
    "https://storage.googleapis.com/shuffle_public/app_images/Elasticsearch_971706758e274c2e4083f2621fb5a6f7.png",
    "https://storage.googleapis.com/shuffle_public/app_images/QRadar_96a3d95a2a73cfdb51ea4a394287ed33.png",
    "https://storage.googleapis.com/shuffle_public/app_images/Splunk-openapi_1995363ec370368ed05a2882ec0ea8fc.png",
  ],
  iam: [
    "https://storage.googleapis.com/shuffle_public/app_images/Okta_OpenAPI_0966d65ac6271b084a0399d2bd97ef51.png",
    "https://storage.googleapis.com/shuffle_public/app_images/Microsoft_Identity_and_Access_1ce1b7a6ee2ed37aa695f336c40159db.png",
  ],
  assets: [
    "https://storage.googleapis.com/shuffle_public/app_images/AWS_Application_Discovery_Service_82db9f20593d503ef28c91a76f99992a.png",
    "https://storage.googleapis.com/shuffle_public/app_images/DefectDojo_8d4d728af6d8bee265008663d8e724d1.png",
    "https://storage.googleapis.com/shuffle_public/app_images/IBM_Containers_API_84175c5c946984a834de854e36d35435.png",
    "https://storage.googleapis.com/shuffle_public/app_images/Wazuh_fb715a176a192687e95e9d162186c97f.png",
  ],
};

const LogoTrack = styled('div')({
  display: 'flex',
  animation: 'scroll 60s linear infinite',
  '@keyframes scroll': {
    '0%': {
      transform: 'translateX(0)',
    },
    '100%': {
      transform: 'translateX(-50%)',
    }
  },
});

const LogoSlide = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '120px',
  padding: '0 20px',
  marginLeft: '90px',
  flex: '0 0 auto',
  '& img': {
    height: '30px',
    maxHeight: '30px',
    width: 'auto',
    objectFit: 'contain',
    filter: 'brightness(0) invert(1)',
    opacity: 0.9,
    transition: 'opacity 0.1s ease',
    '&:hover': {
      opacity: 1,
    },
  },
  '& img[alt="HITACHI"], & img[alt="NEC"], & img[alt="NEOSOFT"], & img[alt="Axians"],  & img[alt="OKIOK"],  & img[alt="TANDEM"], & img[alt="SOPRA_STERIA"] , & img[alt="T_SYSTEMS"]': {
    height: '22px',
  },
  '& img[alt="PLAYPLUG"]': {
    height: '26px',
  },
  '& img[alt="HOSTINGER"]': {
    height: '34px',
  },
});

const HeroSection = (props) => {
  const { globalUrl, isLoaded, isLoggedIn, serverside, userdata } = props;

  const muiTheme = useTheme();
  const [selectedUsecase, setSelectedUsecase] = useState("SIEM alerts");
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('md'));
  const [colorIndex, setColorIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [usecasekeys, setUsecasekeys] = useState([])
  const [isPaused, setIsPaused] = useState(false);
  const [workflowData, setWorkflowData] = useState({
    srcImg: "",
    srcText: "",
    dstImg: "",
    dstText: "",
  });
  const [animationKey, setAnimationKey] = useState(0);

  const lottieRef = useRef(null)
  const [entering, setEntering] = useState(true);

  const isCloud = serverside === true || typeof window === "undefined" ? true
  : window.location.host === "localhost:3002" ||
  window.location.host === "shuffler.io" ||
  window.location.host === "localhost:5002";


  const colors = ["#fb47a0", "#ff8444", "#806bff", "#02cb70"];

  useEffect(() => {
    if (usecases !== undefined && usecases !== null) {
      setUsecasekeys(Object.keys(usecases))
    }
  }, [usecases])

  useEffect(() => {
    const lowercaseUsecase = selectedUsecase?.toLowerCase();
    const foundUsecase = usecaseTypes.find(
      (usecaseType) =>
        usecaseType?.name?.toLowerCase() === lowercaseUsecase ||
        usecaseType?.aliases?.includes(lowercaseUsecase)
    );

    if (foundUsecase?.value?.[0]?.items) {
      const items = foundUsecase.value[0].items;
      
      const srcApp = items[0]?.app_type;
      const srcImg = images[srcApp][Math.floor(Math.random() * images[srcApp].length)];
      let srcText = items[0]?.name;
      if (srcText?.includes("When")) {
        srcText = srcText.split("When")[1].trim();
        srcText = srcText.charAt(0).toUpperCase() + srcText.slice(1);
      }

      const dstApp = items[1]?.app_type;
      const dstImg = dstApp ? images[dstApp][Math.floor(Math.random() * images[dstApp].length)] : "";
      let dstText = items[1]?.name || "";
      if (dstText.includes("Then")) {
        dstText = dstText.split("Then")[1].trim();
        dstText = dstText.charAt(0).toUpperCase() + dstText.slice(1);
      }

      setWorkflowData({ srcImg, srcText, dstImg, dstText });
    }
  }, [selectedUsecase]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!isPaused) {
        setProgress((oldProgress) => {
          if (oldProgress >= 105) {
            const foundIndex = usecasekeys.findIndex(key => key === selectedUsecase)
            var newitem = usecasekeys[foundIndex + 1]
            if (newitem === undefined || newitem === 0) {
              newitem = usecasekeys[1]
            }

            setEntering(false);
            setTimeout(() => {
              setColorIndex(prevIndex => prevIndex === colors.length - 1 ? 0 : prevIndex + 1);
              setSelectedUsecase(newitem);
              setEntering(true);
            }, 250);

            return -18;
          }
          return oldProgress + 1.5;
        });
      }
    }, 40);

    return () => clearInterval(timer);
  }, [selectedUsecase, colorIndex, isPaused, usecasekeys]);

  useEffect(() => {

	if (serverside === true) {
		return 
	}

    // Trigger animation reset when usecase changes
    setAnimationKey(prev => prev + 1);
    
    // Small delay to ensure animation starts from beginning
    const timer = setTimeout(() => {
      if (lottieRef.current) {
        lottieRef.current.setDirection(1);
        lottieRef.current.goToAndPlay(0, true);
      }
    }, 50);

    return () => clearTimeout(timer);
  }, [selectedUsecase]);

  return (
    <Box
      sx={{
        bgcolor: "#1A1A1A",
        color: "white",
        py: 4,
        pt: {
          xl: 10,
        },
        paddingBottom : '0px',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* Announcement Banner */}
      <Chip
        label="✨ Public training dates released!"
        onClick={() => {
          if (isCloud) {
            ReactGA.event({
              category: "hero_section",
              action: "public_training_dates_click",
              label: "go_to_training_public_training",
            })
            window.open("https://shuffler.io/training", "_blank");
          }
        }}
        sx={{
          zoom: {
            md: "0.7",
            xl: "0.9",
          },
          marginTop: {
            xs: "20px",
            md: "0px",
          },
          marginBottom: {
            xs: "70px",
            md: "40px",
            xl: "65px",
          },
          position: 'relative',
          bgcolor: "#212121",
          border: 'none',
          borderRadius: "100px",
          color: "transparent",
          background: "linear-gradient(90deg, #EE57A2 0%, #F2643B 100%)",
          backgroundClip: "text",
          mb: 6,
          fontSize: "16px",
          fontWeight: 500,
          fontFamily: theme.typography.fontFamily,
          '&::before': {
            content: '""',
            position: 'absolute',
            inset: 0,
            borderRadius: "100px",
            padding: '1px', // This is the border width
            background: 'linear-gradient(90deg, #EE57A2 0%, #F2643B 100%)',
            WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            WebkitMaskComposite: 'xor',
            maskComposite: 'exclude',
          },
          '&:hover': {
            background: "linear-gradient(90deg, #FF8544 0%, #FB47A0 100%)",
            color: "black",
          },
        }}
      />

      <Container maxWidth="md">
        {/* Hero Content */}
        <Stack spacing={4} alignItems="center" textAlign="center" sx={{
          zIndex: 1,
        }}>
          <Typography
            variant="h2"
            sx={{ 
              fontSize: {
                xs: "40px",
                sm: "45px",
                md: "55px",
                lg: "60px",
                xl: "70px",
              },
              lineHeight: {
                xs: "16px",
                sm: "40px",
                md: "45px",
                lg: "50px",
                xl: "70px",
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              // backgroundColor: "red",
            }}
            component="h1"
            fontWeight="bold"
          >
            <Box sx={{ 
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
                xl: "row",
              },
              alignItems: "center",
              gap: "15px",
              marginBottom: "10px",
              justifyContent: "center",
              marginLeft: {
                md: "130px",
                lg: "100px",
                xl: "50px",
              },
              // marginRight: {
              //   xs: "0px",
              //   sm: "0px",
              //   md: "-110px",
              //   lg: "-50px",
              //   xl: "0px",
              // },
            }}>
              <span style={{ color: "white", paddingLeft: {
                xs: "10px",
                sm: "10px",
                md: "0px",
                lg: "0px",
                xl: "0px",
              } }}>Automate</span>

	  		  {serverside === true ? null : 
				  <AnimatedBox>
					<AnimatedText 
					  entering={entering}
					  sx={{ 
						color: colors[colorIndex],
						transition: "color 0.3s ease-in-out",
            fontSize: {
              xs: "40px",
              sm: "45px",
              md: "55px",
              lg: "60px",
              xl: "70px",
            },
						lineHeight: {
              xs: "16px",
              sm: "40px",
              md: "45px",
              lg: "50px",
              xl: "70px",
            },
						justifyContent: {
						  xs: "center",
						  sm: "center",
						  md: "flex-start",
						  lg: "flex-start",
						  xl: "flex-start",
						},
						fontWeight: "bold",
						paddingLeft: "10px",
						whiteSpace: "nowrap",
						width: "100%",
					  }}
					>
					  {selectedUsecase?.charAt(0).toUpperCase() + selectedUsecase?.slice(1)}
					</AnimatedText>
				</AnimatedBox>
			}

            </Box>
              <div style={{ color: "white", margin: "auto", zIndex: 1 }}>
                with confidence.
              </div>
          </Typography>

          <Typography
            variant="h5"
             sx={{
              color: "#D8D8D8",
              fontSize: {
                xs: "16px",
                xl: "18px",
              },
              fontFamily: theme.typography.fontFamily,
              zIndex: 1,
              px: {
                xs: "10px",
                sm: "20px",
                lg: "34px",
              },
              textAlign: "center",
              maxWidth: {
                xs: "390px",
                sm: "450px",
                md: "390px",
                lg: "390px",
                xl: "420px",
              },
              margin: "0 auto",
              py: {
                xs: "10px",
                sm: "10px",
                md: "0px",
              }
            }}
          >
            The most versatile workflow automation platform for technical teams.
          </Typography>

          {/* Workflow Demo */}
          <Box sx={{ position: "relative" }}>
           
            <Box sx={{ 
              position: 'relative',
              px: isMobile ? 2 : 0,
              width: '100%',
              marginTop: "-22px",
              zoom: {
                md: "0.7",
                xl: "0.9",
              },
            }}>
               <img
              src="/images/logos/orange-pink.png"
              style={{
                position: "absolute",
                top: isMobile ? -100 : -250,
                left: isMobile ? -50 : -210,
                height: isMobile ? 500 : 700,
                width: isMobile ? 600 : 1350,
                rotate: "180deg",
                filter: "blur(150px)",
                zIndex: 0,
                opacity: 0.8,
              }}
            />
              <WorkflowCard
                onMouseEnter={() => setIsPaused(true)}
                onMouseLeave={() => setIsPaused(false)}
                sx={{
                  backgroundColor: "transparent",
                  borderRadius: "16px",
                  border: "none",
                  zIndex: 1,
                  width: {
                    xs: "310px",
                    sm: "500px",
                    md: "800px",
                    lg: "831px",
                  },
                  position: "relative",
                  marginTop: {
                    xs: "10px",
                    sm: "15px",
                    md: "20px",
                    lg: "23px",
                    xl: "26px",
                  },
                  marginLeft: {
                    xl: "0px",
                  },
                  zIndex: 2,
                  boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(255, 255, 255, 0.1)",
                }}
              >
                <Stack
                  direction={isMobile ? "column" : "row"}
                  alignItems={isMobile ? "center" : "center"}
                  justifyContent="space-between"
                  mb={isMobile ? 2 : 4}
                  sx={{
                    width: "100%",
                    gap: {
                      xs: 1,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: isMobile ? "100%" : "40%",
                      alignItems: "start",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#B6B6B6",
                        mb: 0.8,
                        fontSize: "1.1rem",
                        paddingLeft: {
                          xs: "0px",
                          sm: "8px",
                          lg: "12px",
                        },
                      }}
                    >
                      When
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "rgba(217, 217, 217, 0.2)",
                        borderRadius: "100px",
                        border: "1px solid transparent",
                        px: 1.5,
                        py: 1.5,
                        width: "100%",
                        marginLeft: {
                          xs: "-13px",
                          sm: "-13px",
                          md: "0px",
                        },
                      }}
                    >
                      <img
                        src={workflowData.srcImg}
                        style={{ 
                          height: 50, 
                          width: 50, 
                          borderRadius: 100 ,
                        }}
                      />
	  			
	  				  {serverside === true ? null : 
						  <AnimatedWorkflowText
							variant="h6"
							entering={entering}
							sx={{ 
							  color: "white", 
							  paddingLeft: 2,
							  fontSize: {
								xs: "18px",
								sm: "20px",
								md: "22px",
							  },
							  fontWeight: theme.typography.fontWeightRegular,
							}}
						  >
							{workflowData.srcText}
						  </AnimatedWorkflowText>
						}
                    </Box>
                  </Box>

	  			  {serverside === true ? null : 
					  <Lottie 
						key={animationKey}
						lottieRef={lottieRef}
						animationData={require('../assets/arrow.json')}
						style={{ 
						  width: isMobile ? "120px" : "20%", 
						  height: isMobile ? "45px" : "72px",
						  marginTop: isMobile ? "4px" : "-30px",
						  marginBottom: isMobile ? "-24px" : "0px",
						  marginLeft: isMobile ? "auto" : "10px",
						  marginRight: isMobile ? "-50px" : "-20px",
						  transform: `rotate(${isMobile ? '240deg' : '155deg'})`,
						  filter: "brightness(0) invert(1) opacity(0.7)",
						  mixBlendMode: "soft-light"
						}}
						loop={0}
						autoplay={false}
						speed={0.8}
					  />
				  }

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: isMobile ? "100%" : "40%",
                      alignItems: "start",
                      marginRight: isMobile ? 0 : "20px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "grey.400",
                        mb: 0.8,
                        fontSize: "1.1rem",
                        paddingLeft: {
                          xs: "0px",
                          sm: "8px",
                        },
                      }}
                    >
                      Then
                    </Typography>
                    <Box
                     sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "rgba(217, 217, 217, 0.2)",
                      borderRadius: "100px",
                      border: "1px solid transparent",
                      px: 1.5,
                      py: 1.5,
                      width: "100%",
                      marginLeft: {
                        xs: "-13px",
                        sm: "-13px",
                        md: "-4px",
                      },
                    }}
                    >
                      <img
                        src={workflowData.dstImg}
                        style={{ height: 50, width: 50, borderRadius: 100 }}
                      />
	  			
	  				  {serverside === true ? null : 
						  <AnimatedWorkflowText
							variant="h6"
							entering={entering}
							sx={{ 
							  color: "white", 
							  paddingLeft: 2,
							  fontSize: {
								xs: "18px",
								sm: "20px",
								md: "22px",
							  },
							  fontWeight: theme.typography.fontWeightRegular,
							}}
						  >
							{workflowData.dstText}
						  </AnimatedWorkflowText>
					  }
                    </Box>
                  </Box>
                </Stack>

                <Stack spacing={isMobile ? 2 : 3} direction={isMobile ? "column" : "row"} alignItems="center" sx={{ mt: {
                  xs: 6,
                  sm: 8,
                  md:6
                },  }}>
                  <Button
                    fullWidth
                    component={Link}
                    to={"/contact?category=talk_to_sales"}
                    size="large"
                    sx={{
                      padding: isMobile ? "12px 24px" : "16px 48px",
                      fontSize: "16px",
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      border: "2px solid transparent",
                      borderRadius: "8px",
                      color: "white",
                      fontWeight: theme.typography.fontWeightSemiBold,
                      textTransform: "none",
                      '&:hover': {
                        border: "2px solid #C5C5C5",
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        background: "rgba(255, 255, 255, 0.2)",
                      },
                    }}
                    onClick={() => {
                      if (isCloud) {
                        ReactGA.event({
                          category: "hero_section",
                          action: "talk_to_sales_click",
                          label: "talk_to_sales_click",
                        })
                      }
                    }}
                  >
                    Talk to Sales
                  </Button>
                  <GradientButton
                    fullWidth
                    component={Link}
                    to={isLoggedIn ? "/welcome?tab=2" : "/register"}
                    size="large"
                    sx={{
                      padding: isMobile ? "12px 24px" : "16px 48px",
                      fontSize: "16px",
                    }}
                    onClick={() => {
                      if (isCloud) {
                          ReactGA.event({
                            category: "hero_section",
                            action: "try_usecase_for_free_click",
                            label: "try_usecase_for_free_click",
                          })
                      }
                    }}
                  >
                    Try this usecase for free
                  </GradientButton>
                </Stack>
              </WorkflowCard>
            </Box>

            {/* <Typography
              variant="body1"
              sx={{
                color: "grey.300",
                marginTop: "35px",
                marginLeft: {
                  lg: "20px",
                },
              }}
            >
              or{" "}
              <Link
                to="/usecases"
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontSize: "16px",
                  fontWeight: theme.typography.fontWeightSemiBold,
                  fontFamily: theme.typography.fontFamily,
                  paddingLeft: "8px",
                  position: "relative",
                  zIndex: 3,
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => {
                  e.target.style.color = "#F2643B";
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = "white";
                }}
              >
                Discover other Usecases
              </Link>
            </Typography> */}
          </Box>

          {/* Partner Logos */}
        </Stack>
      </Container>
      <Box sx={{ 
        width: "100%", 
        maxWidth: "1920px",
        overflow: "hidden",
        position: "relative",
        marginTop: {
          xs: "40px",
          md: "60px",
          xl: "100px",
        }, // Adjust this value to match original position
        zoom: {
          md: "0.8",
          xl: "1",
        },
        "&::before, &::after": {
          content: '""',
          position: "absolute",
          top: 0,
          height: "100%",
          width: "100px",
          zIndex: 2,
        },
        "&::before": {
          left: 0,
          background: "linear-gradient(to right, #1A1A1A, transparent)",
        },
        "&::after": {
          right: 0,
          background: "linear-gradient(to left, #1A1A1A, transparent)",
        }
      }}>
        <Box sx={{
          display: 'flex',
          width: '100%',
          height: '100px',
          alignItems: 'center',
          backgroundColor: 'transparent',
          overflow: 'hidden',
          position: 'relative',
        }}>
          <LogoTrack>
            <LogoSlide>
              <img src="/images/companies_logo/axians.svg" alt="Axians" />
              <img src="/images/companies_logo/NEC.svg" alt="NEC" />
              <img src="/images/companies_logo/neosoft.svg" alt="NEOSOFT" />
              <img src="/images/companies_logo/okiok.svg" alt="OKIOK" />
              <img src="/images/companies_logo/nio.svg" alt="NIO" />
              <img src="/images/companies_logo/HITACHI.svg" alt="HITACHI" />
              <img src="/images/companies_logo/quinnipiac_uni.svg" alt="QUINNIPIAC_UNI" />
              <img src="/images/companies_logo/Hostinger.svg" alt="HOSTINGER" />
              <img src="/images/companies_logo/n4l.svg" alt="N4L" />
              <img src="/images/companies_logo/chk.svg" alt="CHK" />
              <img src="/images/companies_logo/sopra_steria.svg" alt="SOPRA_STERIA" />
              <img src="/images/companies_logo/T_systems.svg" alt="T_SYSTEMS" />
              <img src="/images/companies_logo/tandem.svg" alt="TANDEM" />
              <img src="/images/companies_logo/tarlogic.svg" alt="TARLOGIC" />
              <img src="/images/companies_logo/xtention.svg" alt="XTENTION" />
              <img src="/images/companies_logo/playplug.svg" alt="PLAYPLUG" />
            </LogoSlide>
            <LogoSlide>
              <img src="/images/companies_logo/axians.svg" alt="Axians" />
              <img src="/images/companies_logo/NEC.svg" alt="NEC" />
              <img src="/images/companies_logo/neosoft.svg" alt="NEOSOFT" />
              <img src="/images/companies_logo/okiok.svg" alt="OKIOK" />
              <img src="/images/companies_logo/nio.svg" alt="NIO" />
              <img src="/images/companies_logo/HITACHI.svg" alt="HITACHI" />
              <img src="/images/companies_logo/quinnipiac_uni.svg" alt="QUINNIPIAC_UNI" />
              <img src="/images/companies_logo/Hostinger.svg" alt="HOSTINGER" />
              <img src="/images/companies_logo/n4l.svg" alt="N4L" />
              <img src="/images/companies_logo/chk.svg" alt="CHK" />
              <img src="/images/companies_logo/sopra_steria.svg" alt="SOPRA_STERIA" />
              <img src="/images/companies_logo/T_systems.svg" alt="T_SYSTEMS" />
              <img src="/images/companies_logo/tandem.svg" alt="TANDEM" />
              <img src="/images/companies_logo/tarlogic.svg" alt="TARLOGIC" />
              <img src="/images/companies_logo/xtention.svg" alt="XTENTION" />
              <img src="/images/companies_logo/playplug.svg" alt="PLAYPLUG" />
            </LogoSlide>
          </LogoTrack>
        </Box>
      </Box>
   
    </Box>
  );
};

export default HeroSection;
