import React, { useState, useEffect } from "react";

import { toast } from "react-toastify" 
import theme from '../theme.jsx';
import { useNavigate, Link, useParams } from "react-router-dom";
import AppSearchButtons from "../components/AppSearchButtons.jsx";
import { isMobile } from "react-device-detect";
import RenderCytoscape from "../components/RenderCytoscape.jsx";
import {
	Button,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Drawer,
	CircularProgress,
	IconButton,
	Tooltip,
	Box,
} from "@mui/material";

import {
	Check as CheckIcon,
	TrendingFlat as TrendingFlatIcon,
	Close as CloseIcon,
	East as EastIcon, 
} from '@mui/icons-material';

import ReactGA from 'react-ga4';

import WorkflowTemplatePopup2 from "../components/WorkflowTemplatePopup2.jsx";
import ConfigureWorkflow from "../components/ConfigureWorkflow.jsx";

const NewWorkflowTemplatePopup = (props) => {
	const { userdata, appFramework, globalUrl, img1, srcapp, img2, dstapp, title, description, visualOnly, apps, isLoggedIn, isHomePage, getAppFramework,  } = props;

	const [isActive, setIsActive] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [workflowLoading, setWorkflowLoading] = useState(false);
	const [workflow, setWorkflow] = useState({});
	const [showLoginButton, setShowLoginButton] = useState(false);
  	const [appAuthentication, setAppAuthentication] = React.useState(undefined);
  	const [missingSource, setMissingSource] = React.useState(undefined)
  	const [missingDestination, setMissingDestination] = React.useState(undefined);
  	const [configurationFinished, setConfigurationFinished] = React.useState(false)
	const [appSetupDone, setAppSetupDone] = React.useState(false)

	const [requestSent, setRequestSent] = React.useState(false)
  	
	const isCloud = (window.location.host === "localhost:3002" || window.location.host === "shuffler.io") ? true : (process.env.IS_SSR === "true");
	let navigate = useNavigate();
	useEffect(() => {
		if (modalOpen !== true) {
			return
		}

		if (!srcapp.includes(":default") && !dstapp.includes(":default")) {
			if (appSetupDone === false && setAppSetupDone !== undefined) {
				setAppSetupDone(true)
			}

			if (workflow.id === undefined && workflowLoading === false && errorMessage === "") {
				getGeneratedWorkflow() 
			}
		}

		if (missingSource !== undefined && missingDestination !== undefined) {
			if (appSetupDone === false && setAppSetupDone !== undefined) {
				setAppSetupDone(true)
			}
		}

		if (getAppFramework !== undefined) {
			setTimeout(() => {
				getAppFramework()
			}, 500)
		}
	}, [modalOpen, missingSource, missingDestination])

	useEffect(() => {
		//console.log("IN USEEFFECT FOR CONFIG: ", configurationFinished)
		if (configurationFinished === true && workflow.id !== undefined && workflow.id !== null && workflow.id !== "" && isLoggedIn === true) {
			toast.success("Generation Successful. Redirecting to the workflow..")
			setTimeout(() => {
				navigate("/workflows/" + workflow.id)
			}, 2000)
		}
	}, [configurationFinished, workflow, isLoggedIn])

	// 1. Add useEffect to handle the redirect timer
	useEffect(() => {
		let redirectTimer;
		
		if (showLoginButton && modalOpen && workflow.id !== undefined) {
			redirectTimer = setTimeout(() => {
				navigate(`/login?view=landingpageusecases&message=You need to be logged in to generate workflows`)
			}, 4500)
		}

		// Cleanup timer if component unmounts or dependencies change
		return () => {
			if (redirectTimer) clearTimeout(redirectTimer)
		}
	}, [showLoginButton, modalOpen, navigate])

	const imagestyleWrapper = {
        height: 40,
		width: 40,
        borderRadius: 40,
		border: isHomePage ? null : "1px solid rgba(255,255,255,0.3)",
		overflow: "hidden",
		display: "flex",
    }

	const imagestyleWrapperDefault = {
        height: 40,
		width: 40,
        borderRadius: 40,
		border: isHomePage ? null : "1px solid rgba(255,255,255,0.3)",
		overflow: "hidden",
		display: "flex",
	}

    const imagestyle = {
        height: 40,
		width: 40,
        borderRadius: 40,
		border: isHomePage ? null : "1px solid rgba(255,255,255,0.3)",
		overflow: "hidden",
    }

	const imagestyleDefault = {
		display: "block",
		marginLeft: 12,
		marginTop: 11,
		height: 35,
		width: "auto",
	}

	if (title === undefined || title === null || title === "") {
		console.log("No title for workflow template popup!");
		return null
	}

	const getWorkflow = (workflowId) => {
		fetch(`${globalUrl}/api/v1/workflows/${workflowId}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			credentials: "include",
		})
		.then((response) => {
			if (response.status !== 200) {
				console.log("Status not 200 for framework!");
			}

			return response.json();
		})
		.then((responseJson) => {
			if (responseJson.success === false) {
				console.log("Error in workflow loading for ID ", workflowId)
			} else {
				setWorkflow(responseJson)
			}
		})
		.catch((error) => {
			console.log("err in framework: ", error.toString());
			setWorkflowLoading(false)
		})
	}

	const loadAppAuth = () => {	
		if (userdata === undefined || userdata === null || Object.keys(userdata).length === 0) {
			setErrorMessage("You need to be logged in to try the pre-built Workflow Templates.")
			setShowLoginButton(true)
			return
		}

		fetch(`${globalUrl}/api/v1/apps/authentication`, {
    	  method: "GET",
    	  headers: {
    	    "Content-Type": "application/json",
    	    Accept: "application/json",
    	  },
    	  credentials: "include",
    	})
    	  .then((response) => {
    	    if (response.status !== 200) {
    	      console.log("Status not 200 for setting app auth :O!");
    	    }

    	    return response.json();
    	  })
    	  .then((responseJson) => {
    	    if (!responseJson.success) {
    	      	toast("Failed to get app auth: " + responseJson.reason);
				return
    	    }

			  var newauth = [];
			  for (let authkey in responseJson.data) {
				if (responseJson.data[authkey].defined === false) {
				  continue;
				}

				newauth.push(responseJson.data[authkey]);
			  }

			  setAppAuthentication(newauth);
		  })
		  .catch((error) => {
			//toast(error.toString());
			console.log("New auth error: ", error.toString());
		  });
	}

  	// Can create and set workflows
  	const reloadWorkflow = (workflow_id) => {

  	  const new_url = `${globalUrl}/api/v1/workflows/${workflow_id}`
  	  return fetch(new_url, {
  	    method: "GET",
  	    headers: {
  	      "Content-Type": "application/json",
  	      Accept: "application/json",
  	    },
  	    credentials: "include",
  	  })
  	    .then((response) => {
  	      if (response.status !== 200) {
  	        console.log("Status not 200 for workflows :O!");
  	        return;
  	      }
  	      //setSubmitLoading(false);

  	      return response.json();
  	    })
  	    .then((responseJson) => {
		  if (responseJson.success === false) {
		  	if (responseJson.reason !== undefined) {
		  		toast("Error setting workflow: ", responseJson.reason)
		  	} else {
		  		toast("Error setting workflow.")
		  	}

		  	return
		  } else if (responseJson.id !== undefined && responseJson.id !== null && responseJson.id !== "") {
			  setWorkflow(responseJson)
		  }

  	      return responseJson;
  	    })
  	    .catch((error) => {
  	      toast("Failed reloading configured workflow: ", error.toString());
  	    });
  	};

  	// Can create and set workflows
  	const saveWorkflow = (workflowdata) => {

  	  const new_url = `${globalUrl}/api/v1/workflows?set_auth=true`
  	  return fetch(new_url, {
  	    method: "POST",
  	    headers: {
  	      "Content-Type": "application/json",
  	      Accept: "application/json",
  	    },
  	    body: JSON.stringify(workflowdata),
  	    credentials: "include",
  	  })
  	    .then((response) => {
  	      if (response.status !== 200) {
  	        console.log("Status not 200 for workflows :O!");
  	        return;
  	      }
		  console.log("Response in workflow saving: upper ", response)
  	      //setSubmitLoading(false);

  	      return response.json();
  	    })
  	    .then((responseJson) => {
			console.log("Response in workflow saving: ", responseJson)
		  if (responseJson?.success === false) {
		  	if (responseJson?.reason !== undefined) {
		  		toast("Error setting workflow: ", responseJson.reason)
		  	} else {
		  		toast("Error setting workflow.")
		  	}

		  	return
		  }

		  // In case it got a new id, this is to make sure it loads with the correct config
		  if (responseJson?.id !== undefined && responseJson?.id !== null && responseJson?.id !== "") {
			  reloadWorkflow(responseJson.id)
		  }

  	      return responseJson;
  	    })
  	    .catch((error) => {
			console.log("Error in workflow saving: ", error)
  	      toast("Failed generating workflow: ", error.toString());
  	    });
  	};


	const getGeneratedWorkflow = () => {
		if (requestSent === true) {
			return
		}

		if (srcapp.includes(":default") || dstapp.includes(":default")) {
			toast("You need to select both a source and destination app before generating this workflow.")

			if (srcapp !== undefined && srcapp !== null && srcapp.includes(":default")) {
				setMissingSource({
					"type": srcapp.split(":")[0],
				})
			}

			if (dstapp !== undefined && dstapp !== null && dstapp.includes(":default")) {
				setMissingDestination({
					"type": dstapp.split(":")[0],
				})
			}

			return
		}

		setWorkflowLoading(true)
		setRequestSent(true)

		const mergedata = {
			name: title,
			id: "",
			source: {
				app_name: srcapp,
			},
			middle: [],
			destination: {
				app_name: dstapp,
			},
		}

		const url = isCloud ? `${globalUrl}/api/v1/workflows/merge` : `https://shuffler.io/api/v1/workflows/merge`
		fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			credentials: "include",
			body: JSON.stringify(mergedata),
		})
		.then((response) => {
			if (response.status !== 200) {
				setRequestSent(false)
			}
			return response.json()
		})
		.then((responseJson) => {
			console.log("Response in workflow generation: ", responseJson)
			if (responseJson.id !== undefined && responseJson.id !== null && responseJson.id !== "" && responseJson.name !== undefined && responseJson.name !== null && responseJson.name !== "") {
				setWorkflow(responseJson)
				saveWorkflow(responseJson)
				setWorkflowLoading(false)
				return
			}

			if (responseJson.success === false) {
				setWorkflowLoading(false)
				setRequestSent(false)
				toast("Failed to generate workflow.")
				const defaultMessage = "Failed to generate workflow - the Shuffle team has been notified. Contact support@shuffler.io for further assistance."
				setErrorMessage(responseJson.reason ? `${defaultMessage}\n\n${responseJson.reason}` : defaultMessage)
				setIsActive(true)
			} else {
				setIsActive(true)
				setWorkflowLoading(false)
				if (responseJson.workflow_id === "") {
					toast("Failed to build workflow for these tools.")
					console.log("Failed to build workflow for these tools.")
					return
				}
				getWorkflow(responseJson.workflow_id)
			}
		})
		.catch((error) => {
			setWorkflowLoading(false)
			console.log("Error in workflow generation:", error.toString())
			setRequestSent(false)
			setWorkflowLoading(false)
			setErrorMessage("Failed to generate workflow due to an error.")
		})
	}

	if (modalOpen === true && !srcapp.includes(":default") && !dstapp.includes(":default")) {
		if (appSetupDone === false && setAppSetupDone !== undefined) {
			setAppSetupDone(true)
		}

		if (workflow.id === undefined && workflowLoading === false && errorMessage === "") {
			getGeneratedWorkflow() 
		}
	}

	const isFinished = () => {
		// Look for configuration fields being done in the current modal
		// 1. Start by finding the modal
		const template = document.getElementById("workflow-template")
		if (template === null || template == undefined) {
			return true
		}

		// Find item in template with id app-config
		const appconfig = template.getElementsByClassName("app-config")
		if (appconfig === null || appconfig == undefined) {
			return true
		}

		return false
	}
	
	const ModalView = () => {
		if (modalOpen === false) {
			return null
		}

		const divHeight = 500 
		const divWidth = 500 

		return (
        	<Drawer
				anchor={"left"}
        	    open={modalOpen}
        	    onClose={() => {
        	        setModalOpen(false);
        	    }}
        	    PaperProps={{
        	        style: {
						backgroundColor: "black",
        	            color: "white",
        	            minWidth: isHomePage ? null : isMobile ? 300 : 700,
        	            maxWidth: isHomePage ? null : isMobile ? 300 : 700,
						paddingTop: isMobile ? null : 75, 
						itemAlign: "center",
        	        },
        	    }}
        	>
				<IconButton
				  style={{
					zIndex: 5000,
					position: "absolute",
					top: 110,
					right: 25,
					color: "white",
				  }}
				  onClick={() => {
					setModalOpen(false);
				  }}
				>
				  <CloseIcon />
				</IconButton>
				<DialogContent style={{marginTop: 0, marginLeft: isHomePage ? null : isMobile ? null :  75, maxWidth: 500, paddingLeft: 30, marginTop: 20 }}>
					<Typography variant="h4" style={{ fontSize: isMobile ? 20 : null, paddingBottom: 20 }}>
						<b>Configure Workflow</b>
					</Typography> 
					<Typography variant="body2" color="textSecondary" style={{marginTop: 25, }}>
						Selected Workflow:
					</Typography> 
					<div style={{marginBottom: 0, }} id="workflow-template">
						<WorkflowTemplatePopup2 
							globalUrl={globalUrl}
							img1={img1}
							srcapp={srcapp}
							img2={img2}
							dstapp={dstapp}
							title={title}
							description={description}
							visualOnly={true} 
						/>
					</div>
					{workflowLoading ? 
						<div style={{marginTop: 75, textAlign: "center", }}>
							<Typography variant="h4"> Generating the Workflow...
							</Typography> 
							<CircularProgress style={{marginLeft: 0, marginTop: 25, }}/> 
						</div>
						:
						<div>
							<Typography variant="h6" style={{marginTop: 75, }}>
								{errorMessage !== "" ? errorMessage : ""}
							</Typography> 
							{showLoginButton ?
          						<Link to="/register?message=Please login to create workflows&view=home"
          						  style={{
          						    textDecoration: 'none',
									marginBottom: 50, 
          						  }}
          						>
									<Typography
									  style={{
										display: "flex",
										fontSize: 18,
										color: "rgba(255, 132, 68, 1)",
										marginTop: 32,
										fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",
										fontWeight: 550,
									  }}
									>
									  Sign up
									  <EastIcon style={{ marginTop: 3, marginLeft: 7 }} />
									</Typography>
								</Link>
							: null}
						</div>
					}

					{(appSetupDone === false && missingSource !== undefined || missingDestination !== undefined) ? 
						<Typography variant="body1" style={{marginTop: 75, marginBottom: 10, }}>
							{"Find relevant Apps for this Usecase"}
						</Typography>
					: null}

					{(missingSource !== undefined) ? 
						<div style={{}}>
							<AppSearchButtons
								globalUrl={globalUrl}
								appFramework={appFramework}

								appType={missingSource.type}
								AppImage={missingSource.image}

								setMissing={setMissingSource}

								getAppFramework={getAppFramework}
							/>
						</div>
					: null}

					{(missingDestination !== undefined) ? 
						<div style={{}}>
							<AppSearchButtons
								globalUrl={globalUrl}
								appFramework={appFramework}

								appType={missingDestination.type}
								AppImage={missingDestination.image}

								setMissing={setMissingDestination}

								getAppFramework={getAppFramework}
							/>
						</div>
					: null}

					<ConfigureWorkflow
					  userdata={userdata}
					  theme={theme}
					  globalUrl={globalUrl}
					  workflow={workflow}
  					  appAuthentication={appAuthentication}
					  setAppAuthentication={setAppAuthentication}
					  apps={apps}

					  setConfigurationFinished={setConfigurationFinished}
					/>

					{/*errorMessage === "" && configurationFinished === true && workflow.id !== undefined && workflowLoading === false ?
						<Tooltip title="Click to explore the workflow" placement="top">
							<span 
								style={{position: "fixed", display: "flex", right: "10%", top: "20%", border: "1px solid rgba(255,255,255,0.3)", borderRadius: theme.palette?.borderRadius, padding: "15px 30px 15px 30px", backgroundColor: theme.palette.platformColor, cursor: "pointer", }}
								onClick={() => {
									// Open in new tab
									window.open("/workflows/" + workflow.id, "_blank")
								}}
							>
								<Typography variant="h5" style={{ }}>
									Workflow Successfully Generated!
								</Typography>
							</span>
						</Tooltip>
					: null*/}

				</DialogContent>
        	</Drawer>
    	)
	}

	var parsedTitle = title
	const maxlength = 30
	if (title.length > maxlength) {
		parsedTitle = title.substring(0, maxlength) + "..."
	}
	
	parsedTitle = parsedTitle.replaceAll("_", " ")

	const parsedDescription = description !== undefined && description !== null ? description.replaceAll("_", " ") : ""


	return (
		<div >
			<ModalView />
			<Box
				disabled={visualOnly === true}
				onClick={() => {
					if (visualOnly === true) {
						console.log("Not showing more than visuals.")
						return
					}

					if(isCloud) {
						ReactGA.event({
							category: "new_landingpage",
							action: "usecase_click",
							label: title,
						})
					}

					if (errorMessage !== "") {
						toast("Already failed to generate a workflow for this usecase. Please try again later or contact support@shuffler.io.")
						setModalOpen(true)
					} else if (isActive) {
						toast("Workflow already generated. Please try another workflow template!")
						loadAppAuth() 
						setModalOpen(true)
					} else {
						loadAppAuth() 
						setModalOpen(true)
						getGeneratedWorkflow()
					}
				}}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				sx={{
					backgroundColor: "#212121",
					borderRadius: "16px",
					padding: {
						xs: "15px 20px",
						sm: "20px 20px",
						lg: "28px 20px",
					},
					paddingRight: {
						xs: "12px",
						lg: "40px",
					},
					paddingLeft: {
						xs: "12px",
						lg: "24px",
					},
					display: "flex",
					alignItems: "start",
					flexDirection: "column",
					width: {
						xs: "130px",
						sm: "160px",
						lg: "200px",
					},
					minWidth: {
						xs: "130px",
						sm: "160px",
						lg: "200px",
					},
					gap: "20px",
					cursor: isActive && errorMessage !== "" ? "not-allowed" : "pointer",
					"&:hover": {
						backgroundColor: "#2F2F2F",
					},
					position: "relative",
				}}
			>
				<div style={{
					display: "flex",
					gap: "0px",
					position: "relative",
				}}>
					{img1 !== undefined && img1 !== "" && srcapp !== undefined && srcapp !== "" ?
						<Tooltip title={srcapp.replaceAll(":default", "").replaceAll("_", " ").replaceAll(" API", "")} placement="top">
							<img
								src={img1}
								alt="Source"
								style={{
									width: srcapp === "Shuffle Tools" ? "43px" : "40px",
									height: srcapp === "Shuffle Tools" ? "43px" : "40px",
									borderRadius: "100%",
									position: "relative",
									border: srcapp === "Shuffle Tools" ? "0px" : "1.5px solid #1A1A1A",
									zIndex: 1,
								}}
							/>
						</Tooltip>
					: null}
					{img2 !== undefined && img2 !== "" && dstapp !== undefined && dstapp !== "" ?
						<Tooltip title={dstapp.replaceAll(":default", "").replaceAll("_", " ").replaceAll(" API", "")} placement="top">
							<img
								src={img2}
								alt="Destination"
								style={{
									width: "40px",
									height: "40px",
									borderRadius: "100%",
									marginLeft: "-12px",
									border: "1.5px solid #1A1A1A",
									display: dstapp === "NA" ? "none" : "block",
								}}
							/>
						</Tooltip>
					: null}
				</div>
				<Typography
					sx={{
						color: "white",
						fontSize: {
							xs: "14px",
							lg: "16px",
						},
						fontWeight: 600,
						fontFamily: theme.typography.fontFamily
					}}
				>
					{parsedTitle}
				</Typography>
				{parsedDescription && (
					<Typography
						variant="body2"
						sx={{
							color: "rgba(158, 158, 158, 1)",
							fontSize: {
								xs: "12px",
								lg: "14px",
							},
							fontWeight: 400,
							overflow: "hidden",
							maxHeight: "31px"
						}}
					>
						{parsedDescription}
					</Typography>
				)}
				{isActive === true && errorMessage === "" && (
					<CheckIcon
						color="primary"
						sx={{
							position: "absolute",
							color: theme.palette.green,
							top: "10px",
							right: "10px",
							borderRadius: 4
						}}
					/>
				)}
			</Box>


		</div>
	)
}

export default NewWorkflowTemplatePopup 
