import { useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const AppNode = ({
  x,
  y,
  icon,
  size = 40,
  onClick,
  animationPhase,
  onHover,
}) => {
  const [hover, setHover] = useState(false);

  // Calculate position with smooth circular motion
  const offsetX = Math.sin(animationPhase + x * 0.01) * 4;
  const offsetY = Math.cos(animationPhase + y * 0.01) * 4;

  // Scale factor for hover (10% increase)
  const scale = hover ? 1.2 : 1;

  // Add unique clipPath ID for each node
  const clipPathId = `circle-clip-${x}-${y}`;

  return (
    <g
      transform={`translate(${x + offsetX}, ${y + offsetY})`}
      onMouseEnter={() => {
        setHover(true);
        onHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
        onHover(false);
      }}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <defs>
        <clipPath id={clipPathId}>
          <circle r={size / 2} cx={0} cy={0} />
        </clipPath>
      </defs>

      <g transform={`scale(${scale})`}>
        <circle
          r={size / 2}
          fill="white"
          style={{ transition: "all 0.3s ease" }}
        />

        <image
          href={icon}
          x={
            icon.includes("Outlook") ||
            icon.includes("microsoftSentinel") ||
            icon.includes("virus") ||
            icon.includes("ElasticSecurity")
              ? "-9"
              : -size / 2
          }
          y={
            icon.includes("Outlook") ||
            icon.includes("microsoftSentinel") ||
            icon.includes("virus") ||
            icon.includes("ElasticSecurity")
              ? "-9"
              : -size / 2
          }
          width={
            icon.includes("Outlook") ||
            icon.includes("microsoftSentinel") ||
            icon.includes("virus") ||
            icon.includes("ElasticSecurity")
              ? "17"
              : size
          }
          height={
            icon.includes("Outlook") ||
            icon.includes("microsoftSentinel") ||
            icon.includes("virus") ||
            icon.includes("ElasticSecurity")
              ? "17"
              : size
          }
          style={{ transition: "all 0.3s ease" }}
          clipPath={`url(#${clipPathId})`}
        />
      </g>
    </g>
  );
};

const NetworkVisualization = () => {
  const [animationPhase, setAnimationPhase] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const navigate = useNavigate();

  const isLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const isXl = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  const viewBox = isXl
    ? "0 100 600 400"
    : isLg
    ? "0 80 650 450"
    : "0 100 600 400";
  const marginTop = isXl ? "-150px" : isLg ? "-140px" : "-140px";
  const marginLeft = isXl ? "0px" : isLg ? "70px" : "0px";

  // Animation loop with slower speed
  useEffect(() => {
    let animationFrameId;
    let lastTimestamp = 0;
    const speed = 0.03;

    const animate = (timestamp) => {
      if (!isPaused) {
        const deltaTime = timestamp - lastTimestamp;
        if (deltaTime > 0) {
          // Only update if time has passed
          setAnimationPhase((prev) => (prev + speed) % (Math.PI * 2));
        }
        lastTimestamp = timestamp;
      }
      animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [isPaused]);

  const handleNodeHover = (isHovered) => {
    setIsPaused(isHovered);
  };

  const nodes = [
    {
      id: 1,
      icon: "/images/jira.svg",
      x: 50,
      y: 240,
      appId: "eb0c5e572e14ac1140a8355ba93c0d76",
    },
    {
      id: 2,
      icon: "https://storage.googleapis.com/shuffle_public/app_images/Wazuh_fb715a176a192687e95e9d162186c97f.png",
      x: 250,
      y: 200,
      appId: "fb715a176a192687e95e9d162186c97f",
    },
    {
      id: 3,
      icon: "/images/ElasticSecurity.svg",
      x: 480,
      y: 200,
      appId: "fd4f0d47021b1f75f72b05f27fcd1f58",
    },
    {
      id: 4,
      icon: "https://storage.googleapis.com/shuffle_public/app_images/Gmail_794e51c3c1a8b24b89ccc573a3defc47.png",
      x: 190,
      y: 310,
      appId: "794e51c3c1a8b24b89ccc573a3defc47",
    },
    {
      id: 5,
      icon: "/images/microsoftSentinel.svg",
      x: 420,
      y: 280,
      appId: "1783edbefc082d336a5312efd0764133",
    },
    {
      id: 6,
      icon: "/images/Outlook.svg",
      x: 570,
      y: 260,
      appId: "accdaaf2eeba6a6ed43b2efc0112032d",
    },
    {
      id: 7,
      icon: "/images/hive.svg",
      x: 80,
      y: 370,
      appId: "5fefa1911e01a005b54f94dcb6830d82",
    },
    {
      id: 8,
      icon: "/images/virus.svg",
      x: 320,
      y: 350,
      appId: "7ea4a8ac1dbc114c3f67e1e47dd87cc4",
    },
    {
      id: 9,
      icon: "https://storage.googleapis.com/shuffle_public/app_images/MISP_c69ea55c02913030b1cd546f86187878.png",
      x: 540,
      y: 380,
      appId: "c69ea55c02913030b1cd546f86187878",
    },
    {
      id: 10,
      icon: "https://storage.googleapis.com/shuffle_public/app_images/Elasticsearch_971706758e274c2e4083f2621fb5a6f7.png",
      x: 200,
      y: 420,
      appId: "971706758e274c2e4083f2621fb5a6f7",
    },
    {
      id: 11,
      icon: "/images/slack.svg",
      x: 420,
      y: 400,
      appId: "d641997f3f1657ec4b0c91cba020838b",
    },
  ];

  const connections = [
    { from: 0, to: 1 },
    { from: 0, to: 3 },
    { from: 0, to: 6 },

    { from: 1, to: 2 },
    { from: 1, to: 3 },
    { from: 1, to: 4 },
    { from: 1, to: 7 },

    { from: 2, to: 4 },
    { from: 2, to: 5 },

    { from: 3, to: 6 },
    { from: 3, to: 7 },
    { from: 3, to: 9 },

    { from: 4, to: 5 },
    { from: 4, to: 7 },
    { from: 4, to: 8 },
    { from: 4, to: 10 },

    { from: 5, to: 8 },

    { from: 6, to: 9 },

    { from: 7, to: 10 },

    { from: 8, to: 10 },

    { from: 9, to: 7 },
  ];

  const handleNodeClick = (node) => {
    navigate(`/apps/${node?.appId}`);
  };

  const size = isXl ? "31" : isLg ? "30" : "50";

  return (
    <svg
      viewBox={viewBox}
      style={{
        width: "100%",
        height: "100%",
        marginTop: marginTop,
        marginLeft: marginLeft,
        marginBottom: "-90px",
        background: "transparent",
      }}
    >
      {/* Draw connections with constant opacity */}
      {connections.map((connection, index) => {
        const fromNode = nodes[connection.from];
        const toNode = nodes[connection.to];

        // Calculate animated positions for both nodes
        const fromX =
          fromNode.x + Math.sin(animationPhase + fromNode.x * 0.01) * 4;
        const fromY =
          fromNode.y + Math.cos(animationPhase + fromNode.y * 0.01) * 4;
        const toX = toNode.x + Math.sin(animationPhase + toNode.x * 0.01) * 4;
        const toY = toNode.y + Math.cos(animationPhase + toNode.y * 0.01) * 4;

        return (
          <line
            key={index}
            x1={fromX}
            y1={fromY}
            x2={toX}
            y2={toY}
            stroke="rgba(255,255,255,0.2)"
            strokeWidth="1"
          />
        );
      })}


      {nodes.map((node, index) => (
        <AppNode
          key={node.id}
          x={node.x}
          y={node.y}
          icon={node.icon}
          size={size}
          onClick={() => handleNodeClick(node)}
          animationPhase={animationPhase}
          onHover={handleNodeHover}
        />
      ))}
    </svg>
  );
};

export default NetworkVisualization;
