import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga4';


const NewFooter = (props) => {
  const { globalUrl, serverside } = props;
  const isCloud = serverside === true || typeof window === 'undefined' ? true : window.location.host === "localhost:3002" || window.location.host === "shuffler.io";

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const [buttonActive, setButtonActive] = useState(true);

  const footerSections = {
    Products: [
      { name: 'Shuffle', path: '/docs', gaData: { category: "footer", action: "shuffle_click", label: "shuffle_click" } },
      { name: 'Singul', path: '/', gaData: { category: "footer", action: "singul_click", label: "singul_click" } },
      { name: 'API Explorer', path: '/apis', gaData: { category: "footer", action: "api_explorer_click", label: "api_explorer_click" } },
    ],
    Services: [
      { name: 'Professional Services', path: '/professional-services', gaData: { category: "footer", action: "professional_services_click", label: "professional_services_click" } },
      { name: 'Support', path: '/contact?category=support', gaData: { category: "footer", action: "support_click", label: "support_click" } },
      { name: 'Training', path: '/training', gaData: { category: "footer", action: "training_click", label: "training_click" } },
      { name: 'Security Consultation', path: '/contact?category=security_consultation', gaData: { category: "footer", action: "security_consultation_click", label: "security_consultation_click" } },
    ],
    Platform: [
      { name: 'Usecases', path: '/usecases', gaData: { category: "footer", action: "usecases_click", label: "usecases_click" } },
      { name: 'Documentation', path: '/docs', gaData: { category: "footer", action: "documentation_click", label: "documentation_click" } },
      { name: 'Apps', path: '/apps', gaData: { category: "footer", action: "apps_click", label: "apps_click" } },
      { name: 'FAQ', path: '/faq', gaData: { category: "footer", action: "faq_click", label: "faq_click" } },
    ],
    Company: [
      { name: 'About us', path: '/docs/about', gaData: { category: "footer", action: "about_us_click", label: "about_us_click" } },
      { name: 'Articles', path: '/articles', gaData: { category: "footer", action: "articles_click", label: "articles_click" } },
      { name: 'Careers', path: 'mailto:support@shuffler.io?subject=Application for a job at Shuffle', gaData: { category: "footer", action: "careers_click", label: "careers_click" } },
      { name: 'Creators', path: '/creators', gaData: { category: "footer", action: "creators_click", label: "creators_click" } },
    ],
    'Legal & Policies': [
      { name: 'Terms of Services', path: '/legal/terms_of_service', gaData: { category: "footer", action: "terms_of_service_click", label: "terms_of_service_click" } },
      { name: 'Privacy Policy', path: '/legal/privacy_policy', gaData: { category: "footer", action: "privacy_policy_click", label: "privacy_policy_click" } },
      { name: 'Compliance', path: '/legal/compliance', gaData: { category: "footer", action: "compliance_click", label: "compliance_click" } },
    ],
  };

  const socialLinks = [
    { name: 'Discord', icon: '/images/icons/discordGray.svg', link: 'https://discord.gg/B2CBzUm', gaData: { category: "footer", action: "discord_click", label: "discord_click" } },
    { name: 'GitHub', icon: '/images/icons/githubGray.svg', link: 'https://github.com/shuffle/shuffle', gaData: { category: "footer", action: "github_click", label: "github_click" } },
    { name: 'LinkedIn', icon: '/images/icons/linkedInGray.svg', link: 'https://www.linkedin.com/company/shuffleio', gaData: { category: "footer", action: "linkedin_click", label: "linkedin_click" } },
    { name: 'Twitter', icon: '/images/icons/xGray.svg', link: 'https://twitter.com/shuffleio', gaData: { category: "footer", action: "twitter_click", label: "twitter_click" } },
  ];

  const handleNewsletterSubmit = (e) => {
    e.preventDefault();
    
    if (email.length < 4) {
      setMsg("Invalid email");
      setButtonActive(true);
      return;
    }

    setButtonActive(false);
    const data = { "email": email };
    const url = `${globalUrl}/api/v1/functions/newsletter_signup`;
    
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
    .then(response =>
        response.json().then(responseJson => {
          setButtonActive(true);
          if (responseJson["success"] !== false) {
            toast.success(responseJson["reason"]);
            setEmail("");
          } else {
            toast.error(responseJson["reason"]);
          }
        }),
      )
      .catch(error => {
        toast.error("Something went wrong: " + error.toString());
        setButtonActive(true);
      });
  };

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#212121',
        color: 'white',
        pt: 4,
        mt: window.location.pathname.includes("/pricing") ? 0 : 10,
        pb: 4,
      }}
    >
      <Container maxWidth="xl">
        {/* Top Section */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          flexDirection: isMobile ? 'column' : 'row',
          borderBottom: '1px solid #333',
          paddingBottom: 2,
          gap: isMobile ? 3 : 0,
          mb: 6,
        }}>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' }, gap: { xs: 2, lg: 6 }, width: { xs: '100%', lg: 'auto' }}}>
            <Link to="/"
            onClick={() => {
              if (isCloud) {
                ReactGA.event({
                  category: "footer",
                  action: "shuffle_logo",
                  label: "",
                })
              }
            }}
            >
              <img src="/images/logos/topleft_logo.svg" alt="Shuffle" style={{ height: 32 }} />
            </Link>
            <Box sx={{ display: 'flex', gap: { xs: 3, lg:8 }, paddingLeft: { xs: 0, lg: 6 } }}>
              <Link to="/partners" style={{ color: 'white', textDecoration: 'none' }} onClick={() => {
                if (isCloud) {
                  ReactGA.event({
                    category: "footer",
                    action: "partners_click",
                    label: "go_to_partners",
                  })
                }
              }}>
                <Typography sx={{ 
                  fontFamily: theme?.typography?.fontFamily,
                  fontWeight: 500,
                  transition: 'opacity 0.2s ease',
                  '&:hover': {
                    opacity: 0.7,
                  }
                }}>Partners</Typography>
              </Link>
              <Link to="/pricing" style={{ color: 'white', textDecoration: 'none' }} onClick={() => {
                if (isCloud) {
                  ReactGA.event({
                    category: "footer",
                    action: "pricing_click",
                    label: "go_to_pricing",
                  })
                }
              }}>
                <Typography sx={{ 
                  fontFamily: theme?.typography?.fontFamily,
                  transition: 'opacity 0.2s ease',
                  fontWeight: 500,
                  '&:hover': {
                    opacity: 0.7,
                  }
                }}>Pricing</Typography>
              </Link>
              <Link to="/contact" style={{ color: 'white', textDecoration: 'none' }}
                onClick={() => {
                  if (isCloud) {
                    ReactGA.event({
                      category: "footer",
                      action: "contact_us_click",
                      label: "go_to_contact_us",
                    })
                  }
                }}
              >
                <Typography sx={{ 
                  fontFamily: theme?.typography?.fontFamily,
                  transition: 'opacity 0.2s ease',
                  fontWeight: 500,
                  '&:hover': {
                    opacity: 0.7,
                  }
                }}>Contact Us</Typography>
              </Link>
            </Box>
          </Box>
          
          <Box sx={{ 
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
            gap: 2,
            width: '100%',
            maxWidth: isMobile ? '100%' : '500px',
          }}>
            <TextField
              placeholder="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '48px',
                  backgroundColor: '#1A1A1A',
                  color: 'white',
                  borderRadius: '8px',
                  fontFamily: theme?.typography?.fontFamily,
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                },
                '& .MuiOutlinedInput-input': {
                  fontSize: '16px',
                  fontFamily: theme?.typography?.fontFamily,
                  '&::placeholder': {
                    color: '#fff',
                    opacity: 1,
                  },
                },
              }}
            />
            <Button
              variant="contained"
              onClick={handleNewsletterSubmit}
              disabled={!buttonActive}
              sx={{
                height: '45px',
                width: isMobile ? '100%' : '260px',
                backgroundColor: '#E0E0E0',
                color: '#1A1A1A',
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: '#C5C5C5',
                  border: '1px solid #C5C5C5',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#CCCCCC',
                  color: '#666666',
                },
                textTransform: 'none',
                borderRadius: '8px',
                fontSize: '16px',
                fontWeight: 400,
                fontFamily: theme?.typography?.fontFamily,
              }}
            >
              Join Newsletter
            </Button>
          </Box>
        </Box>

        {/* Main Footer Content */}
        <Box sx={{ 
          display: 'grid',
          gridTemplateColumns: isMobile ? '1fr 1fr' : 'repeat(5, 1fr)',
          gap: 4,
          mb: 6,
        }}>
          {Object.entries(footerSections).map(([title, items]) => (
            <Box key={title}>
              <Typography
                variant="h6"
                sx={{
                  color: 'white',
                  mb: 2,
                  fontSize: '16px',
                  fontWeight: 600,
                  fontFamily: theme?.typography?.fontFamily,
                }}
              >
                {title}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {items.map((item) => (
                  <Link
                    key={item.name}
                    to={item.path}
                    style={{ textDecoration: 'none' }}
                    onClick={() => {
                      if (isCloud) {
                        ReactGA.event(item.gaData);
                      }
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#C8C8C8',
                        fontSize: '14px',
                        fontFamily: theme?.typography?.fontFamily,
                        '&:hover': {
                          color: 'white',
                        },
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Link>
                ))}
              </Box>
            </Box>
          ))}
        </Box>

        {/* Bottom Section */}
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 4,
          borderTop: '1px solid rgba(255, 255, 255, 0.1)',
          flexDirection: isMobile ? 'column-reverse' : 'row',
          gap: isMobile ? 2 : 0,
        }}>
          <Box sx={{ display: 'flex', gap: 3 }}>
            {socialLinks.map((social) => (
              <IconButton
                key={social.name}
                component="a"
                href={social.link}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  padding: 0,
                  '&:hover': {
                    opacity: 0.8,
                  },
                }}
                onClick={() => {
                  if (isCloud) {
                    ReactGA.event(social.gaData);
                  }
                }}
              >
                <img
                  src={social.icon}
                  alt={social.name}
                  style={{ width: 24, height: 24 }}
                />
              </IconButton>
            ))}
          </Box>
          
          <Typography sx={{ 
            color: '#C8C8C8', 
            fontSize: '14px', 
            paddingRight: 3,
            fontFamily: theme?.typography?.fontFamily,
          }}>
            Made with 🧡 in Norway and the USA
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default NewFooter;
