import React, { useMemo } from 'react';
import {
  LineChart,
  LineSeries,
  LinearXAxis,
  LinearYAxis,
  LinearXAxisTickSeries,
  LinearYAxisTickSeries,
} from 'reaviz';

import { Typography } from '@material-ui/core';

const LiveExecutionsChart = ({ data }) => {
  console.log("LiveExecutionsChart render with data:", data);


  const sortedData = useMemo(() => {
    if (!data || data.length === 0) return [];
    
    // Sort data by timestamp in ascending order (oldest first)
    const sorted = [...data].sort((a, b) => a.created_at - b.created_at);
    
    return sorted.map(point => ({
      key: new Date(point.created_at * 1000), // Convert timestamp to Date
      metadata: {
        finished: point.finished,
        // failed: point.failed,
        aborted: point.aborted,
        executing: point.executing,
        notification_count: point.notification_count || 0, // Add notification_count
      },
    }));
  }, [data]);

  if (!data || data.length === 0) {
    console.log("No data available");
    return (
      <div style={{ width: '100%', height: 300 }} className="bg-gray-800 flex items-center justify-center text-gray-400">
        No data available
      </div>
    );
  }

  // Data for each metric
  const metrics = [
    { key: 'finished', name: 'Finished', color: '#00F670' },
    // { key: 'failed', name: 'Failed', color: '#FF4444' },
    { key: 'aborted', name: 'Aborted', color: '#FFB020' },
    { key: 'executing', name: 'Executing', color: '#60A5FA' },
    { key: 'notification_count', name: 'Notifications', color: '#FF69B4' }, // Add notification_count
  ];

  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '20px' }}>
      {metrics.map((metric) => (
        <div key={metric.key} style={{ border: '1px solid rgba(255,255,255,0.3)', borderRadius: '8px', padding: '20px' }}>
          <Typography variant="h6" style={{ marginBottom: '10px', color: 'white' }}>
            {metric.name}
          </Typography>
          <LineChart
            data={sortedData.map(point => ({
              key: point.key,
              data: point.metadata[metric.key], // Use the metric key (e.g., 'finished')
            }))}
            height={300}
            xAxis={
              <LinearXAxis
                type="time"
                tickSeries={<LinearXAxisTickSeries interval={0} tickSize={20} />}
              />
            }
            yAxis={
              <LinearYAxis
                tickSeries={<LinearYAxisTickSeries interval={0} />}
              />
            }
            series={
              <LineSeries
                color={metric.color}
              />
            }
          />
        </div>
      ))}
    </div>
  )
}

export default LiveExecutionsChart;
