import React from "react";
import { Typography, Grid, Paper, Collapse, Box } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import theme from "../theme.jsx";

const Capabilities = () => {
  const [expandedItems, setExpandedItems] = React.useState({});
  const [isHovered, setIsHovered] = React.useState(null);

  const handleToggle = (index) => {
    setExpandedItems(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const capabilities = [
    { title: "AI Integrations", description: "Plug and play your AI tools to automate usecases.", isNew: true, iconPath: "/icons/capabilities/AI_Int.svg", hoverIconPath: "/icons/capabilities/AI_Int_hover.svg" },
    { title: "AI Workflow Engine", description: "Generate and build automations based on intent.", isNew: true, iconPath: "/icons/capabilities/AIWorkflow.svg", hoverIconPath: "/icons/capabilities/AIWorkflow_hover.svg" },
    { title: "Pre-built Usecases", description: "Get right into action with Shuffle's pre-built usecases.", isNew: false, iconPath: "/icons/capabilities/Pre-built.svg", hoverIconPath: "/icons/capabilities/Pre-built_hover.svg" },
    { title: "Versatile Automation Engine", description: "Truly automate anything with triggers, actions, scripts, integrations, and more.", isNew: false, iconPath: "/icons/capabilities/Versatile_auto.svg", hoverIconPath: "/icons/capabilities/Versatile_auto_hover.svg" },
    { title: "Made for collaboration", description: "Manage and collaborate with multi-tenancy and multi-user features.", isNew: false, iconPath: "/icons/capabilities/collab.svg", hoverIconPath: "/icons/capabilities/collab_hover.svg" },
    { title: "KMS", description: "Connect to your KMS to control your API keys", isNew: false, iconPath: "/icons/capabilities/kms.svg", hoverIconPath: "/icons/capabilities/kms_hover.svg" },
    { title: "Custom Python Scripts", description: "Run custom script to truly automate and run anything.", isNew: false, iconPath: "/icons/capabilities/python.svg", hoverIconPath: "/icons/capabilities/python_hover.svg" },
    { title: "Triggers", description: "Trigger your automation with webhooks, schedules, pipelines, and more.", isNew: false, iconPath: "/icons/capabilities/triggers.svg", hoverIconPath: "/icons/capabilities/triggers_hover.svg" },
    { title: "API Explorer", description: "Explore APIs and actions from over 2500 platforms.", isNew: false, iconPath: "/icons/capabilities/api_explorer.svg", hoverIconPath: "/icons/capabilities/api_explorer_hover.svg" },
    { title: "API Testing", description: "Test and run APIs to mimic actions inside your automations.", isNew: false, iconPath: "/icons/capabilities/api_testing.svg", hoverIconPath: "/icons/capabilities/api_testing_hover.svg" },
    { title: "IAM", description: "Inbuilt IAM roles to manage how you use Shuffle.", isNew: false, iconPath: "/icons/capabilities/IAM_ICON.svg", hoverIconPath: "/icons/capabilities/IAM_hover.svg" },
    { title: "Singul", description: "Connect and run actions seamlessly between different platforms.", isNew: false, iconPath: "/images/logos/singul.svg", hoverIconPath: "/images/logos/singul.svg" },
  ];

  return (
    <div style={{ padding: "60px 0", backgroundColor: "#212121", color: "white", width: "100%" }}>
      <div style={{ maxWidth: 1250, margin: "0 auto", padding: "0 20px", paddingBottom: "100px" }}>
        <Typography variant="subtitle1" sx={{ textAlign: "center", color: "#FF8544" }}>
          Capabilities
        </Typography>
        <Typography sx={{ textAlign: "center", mb: 1.5, mt: 1.5, fontSize: "40px", fontWeight: 600, fontFamily: theme.typography.fontFamily, color: "white" }}>
          What does Shuffle offer?
        </Typography>
        <Typography sx={{ textAlign: "center", mb: 4, fontSize: "16px", color: "#F1F1F1" }}>
          Why Shuffle is the best choice for your security automation needs.
        </Typography>

        {/* Desktop View - Original Grid */}
        <Grid container spacing={3} sx={{ mt: 5, display: { xs: 'none', md: 'flex' } }}>
          {capabilities.map((capability, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Paper
                onMouseEnter={() => setIsHovered(index)}
                onMouseLeave={() => setIsHovered(null)}
                elevation={0}
                sx={{
                  backgroundColor: "#1A1A1A",
                  height: "100%",
                  padding: "14px 24px",
                  paddingBottom: "0",
                  borderRadius: "16px",
                  transition: "all 0.3s ease",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  paddingTop: "40px",
                //   justifyContent: "center",
                  minHeight: "200px",
                  maxHeight: "200px",
                  position: "relative",
                  overflow: "hidden",

                  "& .icon": {
                    fontSize: "24px",
                    backgroundColor: "#212121",
                    padding: "14px 14px",
                    borderRadius: "8px",
                    width: "fit-content",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "all 0.3s ease",
                  },

                  "& .title-wrapper": {
                    marginTop: "20px",
                    transition: "all 0.3s ease",
                    marginLeft: "4px",
                  },

                  "& .description": {
                    color: "#C8C8C8",
                    fontSize: {
                      md: "12px",
                      lg: "16px"
                    },
                    opacity: 0,
                    transition: "all 0.3s ease",
                  },

                  "&:hover": {
                    "& .icon": {
                      marginBottom: 0,
                      justifyContent: "start",
                      background: capability.title.includes("AI") ? 
                        "linear-gradient(-45deg, #9C5AF2 0%, #EC517C 50%, #FF8544 100%)" : 
                        capability.title.includes("Singul") ?
                        "#07403B" :
                        "linear-gradient(45deg, #FF8444 0%, #F2653B 100%)",
                    },
                    "& .title-wrapper": {
                      transform: "translateY(-40px)",
                      marginLeft: "60px",
                      marginTop: capability.title.length > 20 ? "-9px" : (capability.isNew && capability.title.length > 15) ? "-9px" : "0px",

                      "& .MuiTypography-root": {
                        fontSize: "18px",
                      }
                    },
                    "& .description": {
                      opacity: 0.8,
                      transform: "translateY(-20px)",
                      marginTop: {
                        md: "10px",
                        lg: capability.description.length < 60 ? "30px" : "10px"
                      },
                    }
                  }
                }}
              >
                <span className="icon">
                  <img src={isHovered === index ? capability?.hoverIconPath : capability?.iconPath} alt={capability?.title} style={{ width: "20px", height: "20px" }} />
                </span>
                <div className="title-wrapper">
                  <Typography sx={{ color: "white", fontSize: {
                    md: "18px",
                    lg: "20px"
                  }, fontWeight: 600, display: "inline", paddingTop: capability.title.length < 17 ? "100px" : "0px" }}>
                    {capability.title}
                  </Typography>
                  {capability.isNew && (
                    <span style={{ 
                      backgroundImage: "linear-gradient(90deg, #EE57A2 0%, #F2643B 100%)",
                      WebkitBackgroundClip: "text",
                      color: "transparent",
                      fontSize: "14px", 
                      fontWeight: 500, 
                      marginRight: "8px", 
                      paddingLeft: "8px",
                      fontFamily: theme.typography.fontFamily,
                    }}>
                      New
                    </span>
                  )}
                </div>
                <Typography className="description">
                  {capability.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>

        {/* Mobile View - New Implementation */}
        <Box sx={{ 
          display: { xs: 'flex', md: 'none' }, 
          flexDirection: 'column', 
          gap: 2,
          mt: 5 
        }}>
          {capabilities.map((capability, index) => (
            <Paper
              key={index}
              onClick={() => handleToggle(index)}
              elevation={0}
              sx={{
                backgroundColor: "#1A1A1A",
                borderRadius: "16px",
                padding: "20px",
                transition: "all 0.3s ease",
              }}
            >
              {/* Header */}
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between'
              }}>
                {/* Icon and Title */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Box sx={{
                    backgroundColor: "#212121",
                    padding: "10px",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                    <img 
                      src={capability?.iconPath} 
                      alt={capability?.title} 
                      style={{ width: "20px", height: "20px" }} 
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography sx={{ 
                      color: "white", 
                      fontSize: "18px", 
                      fontWeight: 600 
                    }}>
                      {capability.title}
                    </Typography>
                    {capability.isNew && (
                      <span style={{ 
                        backgroundImage: "linear-gradient(90deg, #EE57A2 0%, #F2643B 100%)",
                        WebkitBackgroundClip: "text",
                        color: "transparent",
                        fontSize: "14px", 
                        fontWeight: 500,
                        fontFamily: theme.typography.fontFamily,
                      }}>
                        New
                      </span>
                    )}
                  </Box>
                </Box>
                
                {/* Arrow Icon */}
                <KeyboardArrowDownIcon 
                  sx={{
                    transform: expandedItems[index] ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                    color: "#F1F1F1"
                  }}
                />
              </Box>

              {/* Collapsible Description */}
              <Collapse in={expandedItems[index]}>
                <Typography sx={{
                  color: "#C8C8C8",
                  fontSize: "14px",
                  mt: 2,
                  pl: 6 // Aligns with the title
                }}>
                  {capability.description}
                </Typography>
              </Collapse>
            </Paper>
          ))}
        </Box>
      </div>
    </div>
  );
};

export default Capabilities;
